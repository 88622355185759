var render = function render(){
  var _vm$part$homologation, _vm$part, _vm$part$analysisInfo, _vm$part2, _vm$part$homologation2, _vm$part$homologation3, _vm$part$homologation4, _vm$part$homologation5, _vm$part$homologation6, _vm$part$homologation8, _vm$part$homologation9, _vm$part$homologation10, _vm$brand, _vm$part3, _vm$part3$homologatio, _vm$part3$homologatio2, _vm$part$homologation11;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.part ? _c('div', {
    staticClass: "page-homologation"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('p', {
    staticClass: "text-wrap display-1 ma-3 text-truncate",
    staticStyle: {
      "max-width": "100% !important"
    }
  }, [_vm.brand ? _c('span', {
    attrs: {
      "title": _vm.brand.name
    }
  }, [_vm._v(_vm._s(_vm.brand.name) + " -")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t('Part')) + " "), _c('span', {
    attrs: {
      "title": _vm.part.name
    }
  }, [_vm._v(_vm._s(_vm.part.name))])]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "info",
      "to": {
        name: 'Part',
        params: {
          brandUUID: `${_vm.$route.params.brandUUID}`,
          partUUID: `${_vm.$route.params.partUUID}`
        }
      },
      "title": _vm.$t('partDescription')
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-eye ")]), _vm._v(" " + _vm._s(_vm.$t('partDescription')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "info",
      "to": {
        name: 'BrandParts',
        params: {
          brandUUID: `${_vm.$route.params.brandUUID}`
        }
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-warehouse ")]), _vm._v(" " + _vm._s(_vm.$t('Warehouse')) + " ")], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _vm.part ? _c('div', [_vm.viewer3dFile && _vm.part ? _c('ViewerCriticalDimension', {
    ref: "viewerCriticalDimension",
    attrs: {
      "part": _vm.part,
      "viewer3d-file": _vm.viewer3dFile
    },
    on: {
      "save": _vm.saveBrandPartCriticalDimensions
    }
  }) : _vm._e(), _c('ViewerObjectsSplitter', {
    ref: "viewerObjectsSplitterRef",
    attrs: {
      "part": _vm.part,
      "part-file": _vm.partFile,
      "display-confirmation": false
    }
  }), _c('v-card', {
    staticClass: "pb-4 mb-3 col"
  }, [_c('v-card-text', [_c('v-row', [_c('div', {
    staticClass: "col-lg-3 col-sm-6 p-3"
  }, [_vm.brand ? _c('PartStatusOverview', {
    attrs: {
      "part": _vm.part,
      "brand": _vm.brand
    },
    on: {
      "modifyPartStatus": _vm.modifyPartStatus,
      "modifyPartHomologationStatus": _vm.modifyHomologationStatus
    }
  }) : _vm._e(), ((_vm$part$homologation = _vm.part.homologation) === null || _vm$part$homologation === void 0 ? void 0 : _vm$part$homologation.rejectionHistory.length) >= 1 ? _c('div', {
    staticClass: "mt-2"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "width": "100%"
    },
    on: {
      "click": _vm.openHomologationRejectionMessagesDialog
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('DisplayRejectionMessages')) + " ")]), _c('v-icon', {
    staticClass: "ml-3"
  }, [_vm._v(" fas fa-comment-dots ")])], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "col-12 col-md p-3"
  }, [_c('div', {
    staticClass: "headline font-weight-bold d-flex"
  }, [_c('div', {
    staticClass: "infoDnaResult mr-3"
  }, [_c('v-icon', {
    staticClass: "mb-1",
    attrs: {
      "color": "orange"
    }
  }, [_vm._v(" fas fa-info-circle ")]), _c('v-card', {
    staticClass: "d-none dnaResultViewer elevation-9"
  }, [_c('h4', {
    staticClass: "text--primary text-center headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('dnaResult', {
    partName: _vm.part.name
  })) + " ")])])], 1), _vm._v(" " + _vm._s(_vm.$t('ResultsOfDNAForm')) + " ")]), _c('v-divider', {
    staticClass: "mt-4"
  }), _vm.part && _vm.part.dna ? _c('v-btn', {
    staticClass: "mt-5 mr-4",
    attrs: {
      "fab": "",
      "absolute": "",
      "right": "",
      "loading": !_vm.part.dna || !_vm.priceEstimates,
      "disabled": _vm.part.homologation && _vm.part.homologation.status && _vm.part.homologation.status !== 'NEW' && _vm.part.homologation.status !== 'VALIDATION',
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.part.homologation && _vm.part.homologation.status === 'NEW' ? _vm.dnaFormModal = true : _vm.dnaFormWarningModal = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" fas fa-pen ")])], 1) : _vm._e(), _vm.part && _vm.part.dna ? _c('DnaFormV3', {
    key: _vm.part._id + _vm.keyComponent,
    ref: "dnaForm",
    attrs: {
      "flex-mode": "",
      "admin": "",
      "size": _vm.part.size ? _vm.part.size : {},
      "dna-basis": _vm.part.dna,
      "part": _vm.part,
      "technologies-and-materials": _vm.technologiesAndMaterials,
      "analysis-status": _vm.part.analysisStatus,
      "estimated-prices": _vm.priceEstimates,
      "bottom-resume": true,
      "editor": false
    }
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dnaFormModal,
      callback: function ($$v) {
        _vm.dnaFormModal = $$v;
      },
      expression: "dnaFormModal"
    }
  }, [_c('v-card', [_c('v-card-title', {
    ref: "DNAForm"
  }, [_vm.part.dna && _vm.part.dna.completed ? _c('div', [_c('v-icon', {
    staticClass: "mb-1 mr-2",
    attrs: {
      "color": "success"
    }
  }, [_vm._v(" fas fa-check-circle ")]), _vm._v(" " + _vm._s(_vm.$t("ResultsOfDNAForm")) + " ")], 1) : _c('div', {
    staticClass: "getAttentionHeartbeat"
  }, [_c('v-icon', {
    staticClass: "mb-1 mr-2",
    attrs: {
      "color": "orange"
    }
  }, [_vm._v(" fas fa-arrow-right ")]), _vm._v(" " + _vm._s(_vm.$t("DNAToBuild")) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.$refs.dnaFormModal.editableData ? _vm.warningCloseDnaForm = true : _vm.dnaFormModal = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _c('span', [_vm._v(_vm._s(_vm.$t('Close')))])], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500"
    },
    model: {
      value: _vm.warningCloseDnaForm,
      callback: function ($$v) {
        _vm.warningCloseDnaForm = $$v;
      },
      expression: "warningCloseDnaForm"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline primary--text mb-6"
  }, [_c('v-icon', [_vm._v(" fas fa-exclamation-triangle mr-4 primary--text ")]), _vm._v(" " + _vm._s(_vm.$t("warningCloseDnaFormTitle")) + " ")], 1), _c('v-card-text', [_c('p', [_vm._v(_vm._s(_vm.$t("warningCloseDnaFormText1")))]), _c('p', [_vm._v(_vm._s(_vm.$t("warningCloseDnaFormText2")))])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    on: {
      "click": function ($event) {
        _vm.warningCloseDnaForm = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Don'tClose")) + " ")]), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": function ($event) {
        _vm.getAdminBrandPart();
        _vm.dnaFormModal = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CloseAndContinueLater")) + " ")])], 1)], 1)], 1)], 1), _c('v-card-text', [_vm.part && _vm.technologiesAndMaterials ? _c('DnaFormV3', {
    key: 'dnaFormDialog' + _vm.keyComponent,
    ref: "dnaFormModal",
    attrs: {
      "dna-basis": _vm.part.dna ? _vm.part.dna : {},
      "part": _vm.part,
      "admin": "",
      "size": _vm.part.size ? _vm.part.size : {},
      "stats": {},
      "estimated-prices": _vm.priceEstimates,
      "analysis-status": _vm.part.analysisStatus,
      "technologies-and-materials": _vm.technologiesAndMaterials,
      "editor": true
    },
    on: {
      "saved": _vm.saveDNA,
      "cancel": _vm.cancelDnaForm,
      "next": function ($event) {
        _vm.dnaFormModal = false;
      },
      "dnaFormWantToContactSupport": function ($event) {
        _vm.$refs.support.dialogSupportEmail = true;
      },
      "openCriticalDimensionsTool": _vm.openCriticalDimensionsTool
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)]), ((_vm$part = _vm.part) === null || _vm$part === void 0 ? void 0 : (_vm$part$analysisInfo = _vm$part.analysisInformation) === null || _vm$part$analysisInfo === void 0 ? void 0 : _vm$part$analysisInfo.outerShells) >= 2 || ((_vm$part2 = _vm.part) === null || _vm$part2 === void 0 ? void 0 : _vm$part2.analysisInformation.innerShells) >= 1 ? _c('v-row', [_c('v-col', {
    attrs: {
      "col": "12"
    }
  }, [_c('v-alert', {
    staticClass: "mb-0 text-center",
    attrs: {
      "type": _vm.part.acceptManufacturingWithMultipleObjects ? 'info' : 'warning',
      "color": _vm.part.acceptManufacturingWithMultipleObjects ? 'info' : 'orange',
      "text": "",
      "outlined": "",
      "prominent": ""
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('PartIsMadeOfMultipleObjects')) + " ")]), !_vm.part.acceptManufacturingWithMultipleObjects ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('MultipleObjectsNotYetValidated')) + " ")]) : _c('p', [_vm._v(" " + _vm._s(_vm.$t('MultipleObjectsValidated')) + " ")]), _c('p', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.openViewerObjectsSplitter
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-eye ")]), _vm._v(" " + _vm._s(_vm.$t('Visualize')) + " ")], 1)], 1)])], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500"
    },
    model: {
      value: _vm.dnaFormWarningModal,
      callback: function ($$v) {
        _vm.dnaFormWarningModal = $$v;
      },
      expression: "dnaFormWarningModal"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline primary--text mb-6"
  }, [_c('v-icon', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" fas fa-exclamation-triangle mb-1 mr-3 ")]), _vm._v(" " + _vm._s(_vm.$t("warning")) + " ")], 1), _c('v-card-text', [_c('p', [_vm._v(" " + _vm._s(_vm.$t("warningAboutModifications")) + " ")])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.dnaFormWarningModal = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('stopModification')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.dnaFormModal = true;
        _vm.dnaFormWarningModal = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('understood')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600"
    },
    model: {
      value: _vm.updateQuoteDialog,
      callback: function ($$v) {
        _vm.updateQuoteDialog = $$v;
      },
      expression: "updateQuoteDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline primary--text mb-6"
  }, [_c('v-icon', {
    attrs: {
      "color": "warning"
    }
  }, [_vm._v(" fas fa-exclamation-triangle mb-1 mr-3 ")]), _vm._v(" " + _vm._s(_vm.$t('updatePricesForOpenedQuotes')) + " ")], 1), _c('v-card-text', [_c('v-list', {
    attrs: {
      "shaped": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "multiple": "",
      "value": _vm.currentQuotesValues
    }
  }, _vm._l(_vm.currentQuotes, function (quote, index) {
    return _c('v-list-item', {
      key: index,
      staticClass: "v-item--active v-list-item--active mb-2",
      class: {
        'orange--text': quote.status === 'READY'
      },
      attrs: {
        "color": quote.status === 'READY' ? 'orange' : 'info'
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": quote.status === 'READY' ? 'orange' : 'info'
      }
    }, [_vm._v(" fas fa-file-signature ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('span', [_vm._v(" N° " + _vm._s(quote.quoteNumber) + " ")]), _c('span', {
      staticClass: "text-caption"
    }, [_vm._v(" (" + _vm._s(_vm.$d(new Date(quote.created), 'numeric', _vm.$userLocale)) + ") ")])]), _c('v-list-item-subtitle', [quote.status === 'LOCKED' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('Status')) + ": " + _vm._s(_vm.$t('QuoteStatusLOCKED')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('Status')) + ": " + _vm._s(_vm.$t('QuoteStatusREADY')) + " ")])])], 1)], 1);
  }), 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.updateQuoteDialog = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('doNotUpdate')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.updateQuotesWithNewPrices
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('update')) + " ")], 1)], 1)], 1)], 1), _vm.part ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-card', {
    staticClass: "mb-3"
  }, [_c('v-card-title', {
    staticClass: "align-center"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-tasks ")]), _c('span', {
    staticClass: "text-h5 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('Homologation')) + " ")]), _c('v-spacer'), _vm.part.status !== 'NEW' && ((_vm$part$homologation2 = _vm.part.homologation) === null || _vm$part$homologation2 === void 0 ? void 0 : _vm$part$homologation2.status) !== 'VALIDATION' && ((_vm$part$homologation3 = _vm.part.homologation) === null || _vm$part$homologation3 === void 0 ? void 0 : _vm$part$homologation3.status) !== 'READY' && ((_vm$part$homologation4 = _vm.part.homologation) === null || _vm$part$homologation4 === void 0 ? void 0 : _vm$part$homologation4.status) !== 'ORDERED' && ((_vm$part$homologation5 = _vm.part.homologation) === null || _vm$part$homologation5 === void 0 ? void 0 : _vm$part$homologation5.status) !== 'TESTING' ? _c('span', {
    staticClass: "text-sm-right"
  }, [_vm.supplierSelected && _vm.part.homologation && (_vm.part.homologation.status === 'NEW' || _vm.part.homologation.status === 'REJECTED') ? _c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.dialogSendToBCMSupplier = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-desktop")]), _vm._v(" " + _vm._s(_vm.$t('TransferTheQuoteRequestToTheSupplier')) + " ")], 1) : _vm._e(), !_vm.supplierSelected ? _c('v-icon', {
    attrs: {
      "large": "",
      "color": "success"
    },
    on: {
      "click": function ($event) {
        _vm.dialogSupplier = true;
        _vm.forceRerender;
      }
    }
  }, [_vm._v(" fas fa-plus-circle ")]) : _vm._e(), _vm.supplierSelected && _vm.part.homologation && (_vm.part.homologation.status === 'NEW' || _vm.part.homologation.status === 'REJECTED') ? _c('v-btn', {
    attrs: {
      "dark": "",
      "color": "orange"
    },
    on: {
      "click": function ($event) {
        _vm.dialogSupplier = true;
        _vm.forceRerender();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-pen")])], 1) : _vm._e()], 1) : _vm._e(), _vm.part.homologation && _vm.part.homologation.serialNumber ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({
          staticClass: "ml-4"
        }, on), [_c('PythonStatus', {
          attrs: {
            "status": _vm.part.homologation.serialNumber.csgStatus
          },
          on: {
            "pythonActionRequired": _vm.openDialogHomologationSerialNumberFileGeneration
          }
        })], 1)];
      }
    }], null, false, 1633113752)
  }, [_c('span', [_vm._v(_vm._s(_vm.tooltipsCSG[_vm.part.homologation.serialNumber.csgStatus]))])]) : _vm._e()], 1), (_vm$part$homologation6 = _vm.part.homologation) !== null && _vm$part$homologation6 !== void 0 && _vm$part$homologation6.supplier && _vm.part.status !== 'NEW' ? _c('v-card-text', [_c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-h6 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('supplier')) + " ")])]), _vm.part.homologation && _vm.part.homologation.supplier && _vm.part.homologation.supplier.address ? _c('v-card-text', [_c('v-divider', {
    staticClass: "mb-4"
  }), _c('h5', {
    staticClass: "title pb-2"
  }, [_vm._v(" " + _vm._s(_vm.part.homologation.supplier.address.company) + " ")]), _c('h6', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.part.homologation.supplier.address.firstName) + " " + _vm._s(_vm.part.homologation.supplier.address.name) + " ")]), _c('p', [_vm._v(_vm._s(_vm.part.homologation.supplier.address.streetAddress))]), _c('p', [_vm._v(" " + _vm._s(_vm.part.homologation.supplier.address.zipCode) + " " + _vm._s(_vm.part.homologation.supplier.address.city) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t(`countries.${_vm.part.homologation.supplier.address.country}`)) + " ")]), _vm.part.homologation.supplier.address.phoneNumber ? _c('p', [_vm._v(" " + _vm._s(_vm.part.homologation.supplier.address.phoneNumber) + " ")]) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-col', [_c('v-card', {
    staticClass: "fill-height"
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "text-h6 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('HomologationType')) + " ")]), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var _vm$part$homologation7;
        var on = _ref2.on;
        return [_vm.part.homologation ? _c('v-card', _vm._g({
          staticClass: "mx-auto mt-6",
          attrs: {
            "width": "140px",
            "height": "122px",
            "color": "info"
          }
        }, on), [_c('v-card-text', {
          staticClass: "d-flex align-center justify-center fill-height"
        }, [(_vm$part$homologation7 = _vm.part.homologation) !== null && _vm$part$homologation7 !== void 0 && _vm$part$homologation7.public ? _c('v-img', {
          staticClass: "mx-auto",
          attrs: {
            "contain": "",
            "max-height": "90px",
            "src": require("../../assets/logo.png")
          }
        }) : _c('v-icon', {
          attrs: {
            "color": "privateSupplier",
            "size": "80px"
          }
        }, [_vm._v(" fas fa-industry ")])], 1)], 1) : _vm._e()];
      }
    }], null, false, 2908724358)
  }, [(_vm$part$homologation8 = _vm.part.homologation) !== null && _vm$part$homologation8 !== void 0 && _vm$part$homologation8.public ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PublicHomologation')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('PrivateHomologation')) + " ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "text-h6 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('Technology')) + " ")]), _c('v-divider', {
    staticClass: "my-3"
  }), _c('span', {
    staticClass: "ml-5 text-h7 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t((_vm$part$homologation9 = _vm.part.homologation) === null || _vm$part$homologation9 === void 0 ? void 0 : _vm$part$homologation9.technology)) + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "text-h6 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('Material')) + " ")]), _c('v-divider', {
    staticClass: "my-3"
  }), _c('span', {
    staticClass: "ml-5 text-h7 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t((_vm$part$homologation10 = _vm.part.homologation) === null || _vm$part$homologation10 === void 0 ? void 0 : _vm$part$homologation10.material)) + " ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-col', [_c('v-card', {
    staticClass: "mb-2"
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-file-alt ")]), _vm._v(" " + _vm._s(_vm.$t('InProgressQuotes')) + " ")], 1), _c('v-card-text', [_c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-list', {
    attrs: {
      "shaped": "",
      "disabled": _vm.currentQuotes.length === 0
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "multiple": "",
      "value": _vm.currentQuotesValues
    }
  }, [_vm.currentQuotes.length === 0 ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-info-circle ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('NoQuoteInProgress')) + " ")])])], 1)], 1) : _vm._e(), _vm._l(_vm.currentQuotes, function (quote, index) {
    return _c('v-list-item', {
      key: index,
      staticClass: "v-item--active v-list-item--active mb-2",
      class: {
        'orange--text': quote.status === 'READY'
      },
      attrs: {
        "color": quote.status === 'READY' ? 'orange' : 'info',
        "to": {
          name: 'Quote',
          params: {
            brandUUID: _vm.$route.params.brandUUID,
            quoteUUID: quote._id,
            partUUID: _vm.$route.params.partUUID
          }
        }
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": quote.status === 'READY' ? 'orange' : 'info'
      }
    }, [_vm._v(" fas fa-file-signature ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('span', [_vm._v(" N° " + _vm._s(quote.quoteNumber) + " ")]), _c('span', {
      staticClass: "text-caption"
    }, [_vm._v(" (" + _vm._s(_vm.$d(new Date(quote.created), 'numeric', _vm.$userLocale)) + ") ")])]), _c('v-list-item-subtitle', [quote.status === 'LOCKED' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('Status')) + ": " + _vm._s(_vm.$t('QuoteStatusLOCKED')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('Status')) + ": " + _vm._s(_vm.$t('QuoteStatusREADY')) + " ")])])], 1)], 1);
  })], 2)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('div', [_c('v-card', {
    staticClass: "mb-3"
  }, [_c('v-card-title', {
    staticClass: "align-center"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-euro-sign ")]), _c('span', {
    staticClass: "text-h5 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('PriceEstimates')) + " ")])], 1), _c('v-card-text', [(_vm$brand = _vm.brand) !== null && _vm$brand !== void 0 && _vm$brand.features.privateSuppliers.length ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('PublicSuppliersPrices'),
      "disabled": !_vm.privatePrices
    },
    on: {
      "change": _vm.getBrandPartEstimatedPricesV2
    },
    model: {
      value: _vm.publicPrices,
      callback: function ($$v) {
        _vm.publicPrices = $$v;
      },
      expression: "publicPrices"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('PrivateSuppliersPrices'),
      "disabled": !_vm.publicPrices
    },
    on: {
      "change": _vm.getBrandPartEstimatedPricesV2
    },
    model: {
      value: _vm.privatePrices,
      callback: function ($$v) {
        _vm.privatePrices = $$v;
      },
      expression: "privatePrices"
    }
  })], 1)], 1) : _vm._e(), _vm.priceEstimates2 ? _c('PriceEstimatesV2', {
    attrs: {
      "part": _vm.part,
      "prices": _vm.priceEstimates2
    }
  }) : _vm._e()], 1)], 1)], 1), _c('div', [_c('v-card', {
    staticClass: "mb-3 col d-flex"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('v-card-title', {
    staticClass: "pa-0 align-center"
  }, [_c('h3', {
    staticClass: "headline font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mb-1 mr-3",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-euro-sign ")]), _vm._v(" " + _vm._s(_vm.$t('Prices')) + " ")], 1)]), _vm.part.homologation && _vm.part.homologation.status !== 'NEW' && _vm.part.status && _vm.pricesHomologation.length > 0 && _vm.part && _vm.supplierSelected && _vm.technologyPart && _vm.materialPart ? _c('v-card', {
    attrs: {
      "flat": "",
      "disabled": _vm.part.status !== 'HOMOLOGATION' || _vm.part.homologation.status !== 'VALIDATION'
    }
  }, [_c('v-divider', {
    staticClass: "my-4"
  }), _c('v-form', {
    ref: "pricesForm"
  }, [_c('v-simple-table', [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('quantity')) + " ")]), _c('th', {
    staticClass: "d-none d-sm-table-cell",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Unit purchase price')) + " ")]), _c('th', {
    staticClass: "d-none d-sm-table-cell",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Margin')) + " ")]), _c('th', {
    staticClass: "d-none d-sm-table-cell",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Unit selling price')) + " ")]), _c('th', {
    staticClass: "d-sm-none col-4",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Prices')) + " ")]), _c('th', {
    staticClass: "d-none d-sm-table-cell",
    attrs: {
      "scope": "col"
    }
  })])]), _c('tbody', [_vm.part.homologation.status === 'SUPPLIER_QUOTATION' ? _c('tr', [_c('td', {
    staticClass: "infoPricesNotSaved d-flex align-center justify-center",
    attrs: {
      "rowspan": _vm.pricesHomologation.length,
      "colspan": "5"
    }
  }, [_c('span', {
    staticClass: "info--text display-1"
  }, [_vm._v(" Creation of the supplier quotation in progress ")])])]) : _vm._e(), _vm._l(_vm.pricesHomologation, function (price, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "type": "number",
        "solo": "",
        "rules": [function (value) {
          return !!value || _vm.$t('quantityRequired');
        }],
        "disabled": price.quantityRequired || _vm.part.homologation.status === 'SUPPLIER_QUOTATION' || _vm.part.homologation.status === 'READY'
      },
      on: {
        "change": function ($event) {
          _vm.verifyPrice();
          _vm.calculatePrices(price, 'quantity', index);
        }
      },
      model: {
        value: price.quantity,
        callback: function ($$v) {
          _vm.$set(price, "quantity", _vm._n($$v));
        },
        expression: "price.quantity"
      }
    })], 1), _c('td', [_c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "type": "number",
        "step": ".01",
        "min": "0",
        "solo": "",
        "append-icon": "fas fa-euro-sign",
        "rules": _vm.pricesRangeRules[index],
        "disabled": _vm.part.homologation.status === 'SUPPLIER_QUOTATION' || _vm.part.homologation.status === 'READY'
      },
      on: {
        "change": function ($event) {
          _vm.verifyPrice();
          _vm.calculatePrices(price, 'purchasePrice', index);
        }
      },
      model: {
        value: price.purchasePrice,
        callback: function ($$v) {
          _vm.$set(price, "purchasePrice", _vm._n($$v));
        },
        expression: "price.purchasePrice"
      }
    })], 1), _c('td', [_c('v-text-field', {
      key: _vm.keyComponent,
      staticClass: "mt-2",
      attrs: {
        "type": "number",
        "min": "0",
        "step": ".01",
        "solo": "",
        "append-icon": "fas fa-percentage",
        "disabled": ""
      },
      model: {
        value: price.margin,
        callback: function ($$v) {
          _vm.$set(price, "margin", $$v);
        },
        expression: "price.margin"
      }
    })], 1), _c('td', [_c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "type": "number",
        "step": ".01",
        "min": "0",
        "solo": "",
        "append-icon": "fas fa-euro-sign",
        "disabled": _vm.part.homologation.status === 'SUPPLIER_QUOTATION' || _vm.part.homologation.status === 'READY',
        "append-outer-icon": _vm.editingPrice && price.margin === null && price.sellingPrice === null ? 'fas fa-times' : ''
      },
      on: {
        "change": function ($event) {
          return _vm.calculatePrices(price, 'sellingPrice', index);
        },
        "click:append-outer": function ($event) {
          return _vm.deletePrice(index);
        }
      },
      model: {
        value: price.sellingPrice,
        callback: function ($$v) {
          _vm.$set(price, "sellingPrice", _vm._n($$v));
        },
        expression: "price.sellingPrice"
      }
    })], 1)]);
  })], 2)])], 1)], 1) : _vm._e(), _vm.part.homologation && _vm.part.homologation.status === 'VALIDATION' && _vm.part && _vm.supplierSelected && _vm.technologyPart && _vm.materialPart ? _c('div', [_vm.pricesHomologation && !_vm.editingPrice ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.addPricesForm
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t('addPricingRange')) + " ")], 1) : _vm._e()], 1) : _vm._e(), _c('transition', {
    staticClass: "flip",
    attrs: {
      "name": "flip"
    }
  }, [_vm.part.homologation && _vm.part.homologation.status === 'VALIDATION' && _vm.modifiedHomologation === true && _vm.requiredPrices === true ? _c('div', {
    staticClass: "mt-5"
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.cancelPriceForm();
        _vm.$refs.infoPricesNotSaved.classList.remove('d-flex');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.modifyHomologationPrices
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-save ")]), _vm._v(" " + _vm._s(_vm.$t('Save')) + " ")], 1)], 1) : _vm._e()])], 1)])], 1), _c('div', [_c('v-card', {
    staticClass: "mb-3 col d-flex"
  }, [_c('div', {
    staticClass: "col p-0"
  }, [_c('v-card-title', {
    staticClass: "pa-0 align-center"
  }, [_c('h3', {
    staticClass: "headline font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mb-1 mr-3",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-industry ")]), _vm._v(" " + _vm._s(_vm.$t('manufacturingInformations')) + " ")], 1)]), _vm.part.homologation && _vm.part.homologation.status !== 'NEW' && _vm.part.status && _vm.pricesHomologation.length > 0 && _vm.part && _vm.supplierSelected && _vm.technologyPart && _vm.materialPart ? _c('v-card', {
    attrs: {
      "flat": "",
      "disabled": _vm.part.status !== 'HOMOLOGATION' || _vm.part.homologation.status !== 'VALIDATION'
    }
  }, [_c('v-divider', {
    staticClass: "my-4"
  }), _c('v-simple-table', [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('PrinterBrand')) + " ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('PrinterModel')) + " ")]), _c('th', {
    staticClass: "d-none d-sm-table-cell",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Material')) + " ")]), _c('th', {
    staticClass: "d-none d-sm-table-cell",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ManufacturingParameters')) + " ")]), _c('th', {
    staticClass: "d-none d-sm-table-cell",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Finish')) + " ")])])]), _c('tbody', [_vm.part.homologation.status === 'SUPPLIER_QUOTATION' ? _c('tr', [_c('td', {
    staticClass: "infoPricesNotSaved d-flex align-center justify-center",
    attrs: {
      "colspan": "5"
    }
  }, [_c('span', {
    staticClass: "info--text display-1"
  }, [_vm._v(" Creation of the supplier quotation in progress ")])])]) : _vm._e(), (_vm$part3 = _vm.part) !== null && _vm$part3 !== void 0 && (_vm$part3$homologatio = _vm$part3.homologation) !== null && _vm$part3$homologatio !== void 0 && (_vm$part3$homologatio2 = _vm$part3$homologatio.manufacturingInformations) !== null && _vm$part3$homologatio2 !== void 0 && _vm$part3$homologatio2.printer ? _c('tr', [_c('td', [_c('v-select', {
    attrs: {
      "rules": [function (value) {
        return !!value || _vm.$t('BrandIsRequired');
      }],
      "items": _vm.printersBrand,
      "solo": "",
      "disabled": _vm.part.homologation.status === 'SUPPLIER_QUOTATION'
    },
    on: {
      "change": function ($event) {
        _vm.printerBrandModified = true;
        _vm.getModels(_vm.part.homologation.manufacturingInformations.printer.brand);
      }
    },
    model: {
      value: _vm.part.homologation.manufacturingInformations.printer.brand,
      callback: function ($$v) {
        _vm.$set(_vm.part.homologation.manufacturingInformations.printer, "brand", $$v);
      },
      expression: "part.homologation.manufacturingInformations.printer.brand"
    }
  })], 1), _c('td', {
    staticClass: "d-none d-sm-table-cell"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.printersModel,
      "rules": [function (value) {
        return !!value || _vm.$t('ModelIsRequired');
      }],
      "disabled": _vm.part.homologation.status === 'SUPPLIER_QUOTATION',
      "solo": ""
    },
    on: {
      "change": function ($event) {
        _vm.printerModelModified = true;
      }
    },
    model: {
      value: _vm.part.homologation.manufacturingInformations.printer.model,
      callback: function ($$v) {
        _vm.$set(_vm.part.homologation.manufacturingInformations.printer, "model", $$v);
      },
      expression: "part.homologation.manufacturingInformations.printer.model"
    }
  })], 1), _c('td', {
    staticClass: "d-none d-sm-table-cell"
  }, [_c('v-text-field', {
    attrs: {
      "rules": [function (value) {
        return !!value || _vm.$t('MaterialIsRequired');
      }],
      "solo": "",
      "disabled": _vm.part.homologation.status === 'SUPPLIER_QUOTATION'
    },
    on: {
      "change": function ($event) {
        _vm.materialModified = true;
      }
    },
    model: {
      value: _vm.part.homologation.manufacturingInformations.material,
      callback: function ($$v) {
        _vm.$set(_vm.part.homologation.manufacturingInformations, "material", $$v);
      },
      expression: "part.homologation.manufacturingInformations.material"
    }
  })], 1), _c('td', {
    staticClass: "d-none d-sm-table-cell pt-2"
  }, [_c('v-textarea', {
    attrs: {
      "auto-grow": true,
      "rows": 2,
      "solo": "",
      "disabled": _vm.part.homologation.status === 'SUPPLIER_QUOTATION'
    },
    on: {
      "change": function ($event) {
        _vm.manufacturingParametersModified = true;
      }
    },
    model: {
      value: _vm.part.homologation.manufacturingInformations.manufacturing_parameters,
      callback: function ($$v) {
        _vm.$set(_vm.part.homologation.manufacturingInformations, "manufacturing_parameters", $$v);
      },
      expression: "\n                      part.homologation.manufacturingInformations.manufacturing_parameters\n                    "
    }
  })], 1), _c('td', {
    staticClass: "d-none d-sm-table-cell pt-2"
  }, [_c('v-textarea', {
    attrs: {
      "auto-grow": true,
      "rows": 2,
      "solo": "",
      "disabled": _vm.part.homologation.status === 'SUPPLIER_QUOTATION'
    },
    on: {
      "change": function ($event) {
        _vm.finishModified = true;
      }
    },
    model: {
      value: _vm.part.homologation.manufacturingInformations.finish,
      callback: function ($$v) {
        _vm.$set(_vm.part.homologation.manufacturingInformations, "finish", $$v);
      },
      expression: "part.homologation.manufacturingInformations.finish"
    }
  })], 1)]) : _vm._e()])])], 1) : _vm._e(), _c('v-card-actions', [_c('transition', {
    staticClass: "flip",
    attrs: {
      "name": "flip"
    }
  }, [_vm.printerBrandModified || _vm.printerModelModified || _vm.materialModified || _vm.manufacturingParametersModified || _vm.finishModified ? _c('div', [_vm.part.homologation && _vm.part.homologation.status === 'READY' ? _c('v-alert', {
    attrs: {
      "text": "",
      "color": "error",
      "border": "left"
    }
  }, [_c('v-col', [_c('h2', [_c('v-icon', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" fas fa-exclamation-triangle mb-2 mr-2 primary--text ")]), _vm._v(" " + _vm._s(_vm.$t('warning')) + " ")], 1), _c('div', [_vm._v(_vm._s(_vm.$t('warningAboutModifications')))])]), _c('v-spacer'), _c('v-col', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.cancelManufacturingInformations
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('stopModification')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.showButtonToSave = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('understood')) + " ")], 1)], 1)], 1) : _vm._e(), _vm.showButtonToSave || _vm.part.homologation && _vm.part.homologation.status === 'VALIDATION' ? _c('div', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.cancelManufacturingInformations
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.modifyManufacturingInformations
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-save ")]), _vm._v(" " + _vm._s(_vm.$t('Save')) + " ")], 1)], 1) : _vm._e()], 1) : _vm._e()])], 1)], 1)])], 1), _c('div', [_c('v-card', {
    staticClass: "mb-3 col d-flex"
  }, [_c('div', {
    staticClass: "col p-0"
  }, [_c('v-card-title', {
    staticClass: "pa-0 align-center"
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-truck-loading ")]), _c('h3', {
    staticClass: "headline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('DeliveryConfiguration')) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "loading": _vm.loadingBoxes
    },
    on: {
      "click": _vm.estimateDeliveryBoxes
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-sync-alt ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('ComputeBoxesEstimations')) + " ")])], 1)], 1), _vm.boxes.length ? _c('div', [_c('v-divider', {
    staticClass: "my-5"
  }), _c('div', {
    ref: "infoPricesNotSaved",
    staticClass: "d-none infoPricesNotSaved"
  }, [_c('p', {
    staticClass: "headline primary--text"
  }, [_c('v-icon', {
    staticClass: "mb-1 mr-2"
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('PleaseSavePricesBefore')) + " ")], 1)]), _c('v-card', {
    staticClass: "col col-xl-7 ma-auto",
    attrs: {
      "disabled": _vm.part.homologation.status === 'READY'
    }
  }, [_c('v-card-title', [_c('span', [_vm._v(_vm._s(_vm.$t('SizeAndWeight')))]), _c('v-spacer'), _vm.part.homologation.status !== 'READY' ? _c('div', [!_vm.partSizeAndWeightEdit ? _c('v-btn', {
    staticClass: "text-none pa-0",
    attrs: {
      "color": "orange",
      "title": _vm.$t('Edit'),
      "text": "",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.partSizeAndWeightEdit = !_vm.partSizeAndWeightEdit;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-edit ")]), _vm._v(" " + _vm._s(_vm.$t('Edit')) + " ")], 1) : _c('v-icon', {
    staticClass: "pr-2",
    attrs: {
      "color": "orange",
      "title": _vm.$t('Cancel'),
      "small": ""
    },
    on: {
      "click": _vm.cancelPartSizeAndWeightEdit
    }
  }, [_vm._v(" fas fa-times ")])], 1) : _vm._e()], 1), _c('v-divider', {
    staticClass: "my-2 mx-4"
  }), _vm.partSizeAndWeightEdit ? _c('v-simple-table', {
    staticClass: "text-center"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('width (mm)')) + " ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('depth (mm)')) + " ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('height (mm)')) + " ")])])]), _c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('RealSize')) + " ")]), _c('td', [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "type": "number",
      "min": "1",
      "step": ".01",
      "solo": "",
      "persistent-hint": ""
    },
    on: {
      "input": function ($event) {
        return _vm.verifyRealValue(null);
      }
    },
    model: {
      value: _vm.part.size.width,
      callback: function ($$v) {
        _vm.$set(_vm.part.size, "width", _vm._n($$v));
      },
      expression: "part.size.width"
    }
  })], 1), _c('td', [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "type": "number",
      "min": "1",
      "step": ".01",
      "solo": "",
      "persistent-hint": ""
    },
    on: {
      "input": function ($event) {
        return _vm.verifyRealValue(null);
      }
    },
    model: {
      value: _vm.part.size.depth,
      callback: function ($$v) {
        _vm.$set(_vm.part.size, "depth", _vm._n($$v));
      },
      expression: "part.size.depth"
    }
  })], 1), _c('td', [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "type": "number",
      "min": "1",
      "step": ".01",
      "solo": "",
      "persistent-hint": ""
    },
    on: {
      "input": function ($event) {
        return _vm.verifyRealValue(null);
      }
    },
    model: {
      value: _vm.part.size.height,
      callback: function ($$v) {
        _vm.$set(_vm.part.size, "height", _vm._n($$v));
      },
      expression: "part.size.height"
    }
  })], 1)]), _c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Weight')) + " ")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c('div', {
    staticClass: "col-sm-5 col-xl-4 pa-0"
  }, [_c('v-text-field', {
    staticClass: "mt-2 inputWithoutArrows",
    attrs: {
      "type": "number",
      "solo": "",
      "append-icon": "fas fa-weight-hanging"
    },
    on: {
      "input": function ($event) {
        return _vm.verifyRealValue(null);
      }
    },
    model: {
      value: _vm.weight,
      callback: function ($$v) {
        _vm.weight = _vm._n($$v);
      },
      expression: "weight"
    }
  })], 1)])])])]) : _c('v-simple-table', {
    staticClass: "text-center"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('width (mm)')) + " ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('depth (mm)')) + " ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('height (mm)')) + " ")])])]), _c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('RealSize')) + " ")]), _c('td', [_vm._v(_vm._s(_vm.part.size.width))]), _c('td', [_vm._v(_vm._s(_vm.part.size.depth))]), _c('td', [_vm._v(_vm._s(_vm.part.size.height))])]), _c('tr', [_c('th', {
    attrs: {
      "scope": "row"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Weight')) + " ")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c('div', {
    staticClass: "col-sm-3 col-xl-3 pa-0"
  }, [_vm._v(" " + _vm._s(_vm.weight) + " ")])])])])]), _vm.partSizeAndWeightEdit ? _c('v-card-actions', [_vm.part.homologation.status !== 'READY' && _vm.modifiedSizeAndWeight === true && _vm.requiredPrices === true && _vm.arraysAreEqual(_vm.part.homologation.prices, _vm.pricesHomologation) !== false ? _c('div', {
    staticClass: "text-right mt-5"
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.getAdminBrandPart
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.modifyPartSizeAndWeight
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-save ")]), _vm._v(" " + _vm._s(_vm.$t('Save')) + " ")], 1)], 1) : _vm._e()]) : _vm._e()], 1), _vm.boxes && _vm.boxes.length > 0 ? _c('v-card', {
    staticClass: "ma-auto tableSizes mt-4"
  }, [_c('v-simple-table', {
    staticClass: "deliveryTable text-center mt-5 primary--text md-overline scrollBarTableSizes"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col",
      "rowspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Quantity')) + " ")]), _c('th', {
    staticClass: "text-right",
    attrs: {
      "scope": "col"
    }
  }, [_c('span', {
    staticClass: "body-2 font-weight-bold primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('boxFormat')) + " ")])]), _c('th', {
    staticClass: "success lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" A3 ")]), _c('th', {
    staticClass: "warning lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" A5 ")]), _c('th', {
    staticClass: "error lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" A6 ")]), _c('th', {
    staticClass: "primary lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" A9 ")]), _c('th', {
    staticClass: "orange lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" A16 ")])]), _c('tr', [_c('th', {
    staticClass: "text-right",
    attrs: {
      "scope": "col"
    }
  }, [_c('span', {
    staticClass: "body-2 font-weight-bold primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('boxCapacity')) + " ")])]), _c('th', {
    staticClass: "success lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('maxCapacityPerBox', _vm.boxes[0]['A3'].capacity)) + " ")]), _c('th', {
    staticClass: "warning lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('maxCapacityPerBox', _vm.boxes[0]['A5'].capacity)) + " ")]), _c('th', {
    staticClass: "error lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('maxCapacityPerBox', _vm.boxes[0]['A6'].capacity)) + " ")]), _c('th', {
    staticClass: "primary lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('maxCapacityPerBox', _vm.boxes[0]['A9'].capacity)) + " ")]), _c('th', {
    staticClass: "orange lighten-4 text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('maxCapacityPerBox', _vm.boxes[0]['A16'].capacity)) + " ")])])]), _c('tbody'), _c('tbody', [_vm._l(_vm.boxes, function (box, index) {
    return [_c('tr', {
      key: 'tr' + box.for + index
    }, [_c('td', {
      attrs: {
        "rowspan": "2"
      }
    }, [_vm._v(" " + _vm._s(box.for) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('numberOfboxes')))])]), _c('td', {
      staticClass: "success lighten-4"
    }, [_vm._v(" " + _vm._s(_vm.$tc('numberBoxes', box['A3'].quantity)) + " ")]), _c('td', {
      staticClass: "warning lighten-4"
    }, [_vm._v(" " + _vm._s(_vm.$tc('numberBoxes', box['A5'].quantity)) + " ")]), _c('td', {
      staticClass: "error lighten-4"
    }, [_vm._v(" " + _vm._s(_vm.$tc('numberBoxes', box['A6'].quantity)) + " ")]), _c('td', {
      staticClass: "primary lighten-4"
    }, [_vm._v(" " + _vm._s(_vm.$tc('numberBoxes', box['A9'].quantity)) + " ")]), _c('td', {
      staticClass: "orange lighten-4"
    }, [_vm._v(" " + _vm._s(_vm.$tc('numberBoxes', box['A16'].quantity)) + " ")])]), _c('tr', {
      key: 'tr' + index
    }, [_c('td', {
      staticClass: "text-right"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('boxWeight')))])]), _c('td', {
      staticClass: "success lighten-4"
    }, [_vm._v(" " + _vm._s(`${box['A3'].weight} Kg`) + " ")]), _c('td', {
      staticClass: "warning lighten-4"
    }, [_vm._v(" " + _vm._s(`${box['A5'].weight} Kg`) + " ")]), _c('td', {
      staticClass: "error lighten-4"
    }, [_vm._v(" " + _vm._s(`${box['A6'].weight} Kg`) + " ")]), _c('td', {
      staticClass: "primary lighten-4"
    }, [_vm._v(" " + _vm._s(`${box['A9'].weight} Kg`) + " ")]), _c('td', {
      staticClass: "orange lighten-4"
    }, [_vm._v(" " + _vm._s(`${box['A16'].weight} Kg`) + " ")])])];
  })], 2)])], 1) : _vm._e()], 1) : _vm._e()], 1)])], 1), _c('div', {
    staticClass: "col text-center"
  }, [((_vm$part$homologation11 = _vm.part.homologation) === null || _vm$part$homologation11 === void 0 ? void 0 : _vm$part$homologation11.status) === 'VALIDATION' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "orange",
      "width": "100%",
      "x-large": "",
      "dark": "",
      "disabled": _vm.modifiedHomologation === true || _vm.modifiedSizeAndWeight === true
    },
    on: {
      "click": function ($event) {
        return _vm.modifyHomologationStatus('READY');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "large": ""
    }
  }, [_vm._v(" far fa-check-circle ")]), _c('span', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s(_vm.$t('ValidateHomologation')))])], 1) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "primary-title": ""
    },
    model: {
      value: _vm.dialogBeforeFinishHomologationLabel,
      callback: function ($$v) {
        _vm.dialogBeforeFinishHomologationLabel = $$v;
      },
      expression: "dialogBeforeFinishHomologationLabel"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-icon', {
    staticClass: "mb-1 mr-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fa fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('BeCareful')) + " ")], 1), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('v-card', {
    staticClass: "pa-4 text-center",
    attrs: {
      "elevation": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.$t('beforeFinishHomologationDialogText'))
    }
  }), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.dialogBeforeFinishHomologationLabel = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('Close')) + " ")], 1), _vm.part.homologation && _vm.part.homologation.status === 'VALIDATION' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "orange",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        return _vm.modifyHomologationStatus('READY');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('ValidateHomologation')) + " ")], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600",
      "primary-title": ""
    },
    model: {
      value: _vm.dialogBeforeModifyPartAndHomologationStatus,
      callback: function ($$v) {
        _vm.dialogBeforeModifyPartAndHomologationStatus = $$v;
      },
      expression: "dialogBeforeModifyPartAndHomologationStatus"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-icon', {
    staticClass: "mb-1 mr-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fa fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('BeCareful')) + " ")], 1), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('v-card-text', {
    staticClass: "pa-4 text-center"
  }, [_c('p', {
    staticClass: "title font-weight-regular",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('beforeModifyPartAndHomologationStatusDialogText'))
    }
  }), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-chip', {
    staticStyle: {
      "text-decoration": "line-through !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.partStatus)) + " ")]), _c('span', {
    staticClass: "mx-5 title font-weight-regular"
  }, [_vm._v(_vm._s(_vm.$t('to')))]), _c('v-chip', {
    staticClass: "px-8",
    attrs: {
      "large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.newStatus)) + " ")])], 1), _vm.part.homologation && _vm.part.homologation.status === 'VALIDATION' && !_vm.weight && _vm.newStatus !== 'NEW' ? _c('v-card', {
    staticClass: "ma-8 pa-6 error lighten-3"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('TextIfHomologationIsVALIDATIONAndNotComplete')) + " ")])]) : _vm._e()], 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mr-1",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.newStatus = null;
        _vm.dialogBeforeModifyPartAndHomologationStatus = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _vm.newStatus === 'HOMOLOGATED' && _vm.weight || _vm.newStatus !== 'HOMOLOGATED' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.changeStatus(_vm.newStatus);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('ChangeStatus')) + " ")], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "primary-title": "",
      "persistent": ""
    },
    model: {
      value: _vm.dialogLeavePage,
      callback: function ($$v) {
        _vm.dialogLeavePage = $$v;
      },
      expression: "dialogLeavePage"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "info",
      "large": ""
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('beforeLeaveTitle')) + " ")], 1), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('div', {
    staticClass: "d-md-flex justify-space-around"
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('beforeLeaveText')) + " ")])], 1), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "info text-none",
    on: {
      "click": function ($event) {
        _vm.linkToLeave = '';
        _vm.dialogLeavePage = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "orange text-none",
    attrs: {
      "dark": ""
    },
    on: {
      "click": function ($event) {
        _vm.leavePage();
        _vm.dialogLeavePage = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('Leave')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "primary-title": "",
      "persistent": ""
    },
    model: {
      value: _vm.dialogSendToBCMSupplier,
      callback: function ($$v) {
        _vm.dialogSendToBCMSupplier = $$v;
      },
      expression: "dialogSendToBCMSupplier"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('SendToBCMSupplierTitle')) + " ")]), _c('v-divider', {
    staticClass: "mt-1"
  }), _vm.supplierSelected ? _c('v-card', {
    staticClass: "px-10 py-6 text-center",
    attrs: {
      "elevation": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.$t('SendToBCMSupplierText', {
        supplier: _vm.supplierSelected.name
      }))
    }
  }) : _vm._e(), _vm.priceDetected ? _c('v-card', {
    staticClass: "pa-4 text-center",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-row', {
    staticClass: "py-4"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('v-icon', {
    staticClass: "ma-3",
    attrs: {
      "color": "error",
      "large": ""
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")])], 1), _c('div', {
    staticClass: "col-10"
  }, [_vm._v(" " + _vm._s(_vm.$t('messageDetectionPrice')) + " ")])]), _c('v-row', [_c('v-checkbox', {
    staticClass: "mx-auto",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('wantToDeletePrice'))
          }
        })];
      },
      proxy: true
    }], null, false, 2803738477),
    model: {
      value: _vm.resetPrice,
      callback: function ($$v) {
        _vm.resetPrice = $$v;
      },
      expression: "resetPrice"
    }
  })], 1)], 1) : _vm._e(), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "error text-none",
    on: {
      "click": function ($event) {
        _vm.dialogSendToBCMSupplier = false;
        _vm.resetPrice = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _vm.supplierSelected && _vm.part.homologation && (_vm.part.homologation.status === 'NEW' || _vm.part.homologation.status === 'REJECTED') ? _c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.sendToBCMSupplier
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-desktop ")]), _vm._v(" " + _vm._s(_vm.$t('TransferTheQuoteRequestToTheSupplier')) + " ")], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "1000",
      "primary-title": ""
    },
    model: {
      value: _vm.dialogSupplier,
      callback: function ($$v) {
        _vm.dialogSupplier = $$v;
      },
      expression: "dialogSupplier"
    }
  }, [_c('v-card', [_c('v-card', {
    staticClass: "px-3",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "headline mb-5 pr-8 text-center text--primary"
  }, [_c('v-img', {
    staticClass: "mr-5",
    attrs: {
      "src": require("../../../public/img/icons/printer.svg"),
      "alt": "printer image",
      "max-width": "50px",
      "max-height": "50px"
    }
  }), _vm._v(" " + _vm._s(_vm.$t(`ChooseASupplierTitle`)) + " ")], 1), _c('v-card-text', [_c('v-alert', {
    attrs: {
      "outlined": "",
      "color": "primary",
      "icon": "fas fa-tasks",
      "prominent": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('supplierDialogInformationText')) + " ")]), _vm.brand && _vm.brand.features.privateSuppliers.length > 0 ? _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "info",
      "prominent": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('BrandHasPrivateSuppliers')) + " ")]) : _vm._e()], 1), _vm.homologationDialogStep === 1 ? _c('v-card', {
    key: _vm.keyComponent,
    staticClass: "mx-3"
  }, [_c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchDialogSuppliers,
      callback: function ($$v) {
        _vm.searchDialogSuppliers = $$v;
      },
      expression: "searchDialogSuppliers"
    }
  })], 1), _c('v-data-table', {
    key: _vm.supplierDatatableKey,
    attrs: {
      "headers": _vm.headersDialogSuppliers,
      "items": _vm.suppliers,
      "search": _vm.searchDialogSuppliers
    },
    scopedSlots: _vm._u([{
      key: `item.supplier`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "subtitle-2 text-no-wrap"
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: `item.city`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.address.city) + " ")];
      }
    }, {
      key: `item.country`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.$t(`countries.${item.address.country}`)) + " ")];
      }
    }, {
      key: `item.unitaryPrice`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm.suppliersEstimatedPrices[item._id][_vm.part.dna.technology][_vm.part.dna.material] ? [_vm._v(" " + _vm._s(_vm.$n(_vm.suppliersEstimatedPrices[item._id][_vm.part.dna.technology][_vm.part.dna.material].max, 'currency')) + " ")] : [_vm._v(" " + _vm._s(_vm.$t('notConfigured')) + " ")]];
      }
    }, {
      key: `item.bulkPrice`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm.suppliersEstimatedPrices[item._id][_vm.part.dna.technology][_vm.part.dna.material] ? [_vm._v(" " + _vm._s(_vm.$n(_vm.suppliersEstimatedPrices[item._id][_vm.part.dna.technology][_vm.part.dna.material].min, 'currency')) + " ")] : [_vm._v(" " + _vm._s(_vm.$t('notConfigured')) + " ")]];
      }
    }, {
      key: `item.action`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item ? _c('v-btn', {
          staticClass: "my-3",
          attrs: {
            "text": "",
            "small": "",
            "fab": ""
          },
          on: {
            "click": function ($event) {
              return _vm.chooseSupplier(item);
            }
          }
        }, [_vm.supplierSelected && item && item._id === _vm.supplierSelected._id ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" fas fa-check-square ")]) : _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" far fa-square ")])], 1) : _vm._e(), !_vm.suppliersWithCompatiblePrinters.has(item._id) ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref9) {
              var on = _ref9.on;
              return [_c('v-icon', _vm._g({
                staticClass: "ml-2",
                attrs: {
                  "color": "warning"
                }
              }, on), [_vm._v(" fas fa-exclamation-triangle ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('SupplierPrintersSizeNotCompatible')) + " ")])]) : _vm._e()];
      }
    }], null, true)
  })], 1) : _vm._e(), _c('v-divider', {
    staticClass: "my-5"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.getAdminBrandPart();
        _vm.dialogSupplier = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('Close')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "disabled": !_vm.supplierSelected || _vm.part.homologation && _vm.part.homologation.supplier && _vm.supplierSelected._id === _vm.part.homologation.supplier._id
    },
    on: {
      "click": function ($event) {
        return _vm.createHomologationBasicsProperties(_vm.supplierSelected);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('SaveAndClose')) + " ")], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "width": "600px"
    },
    model: {
      value: _vm.dialogGenerateHomologationSerialNumber,
      callback: function ($$v) {
        _vm.dialogGenerateHomologationSerialNumber = $$v;
      },
      expression: "dialogGenerateHomologationSerialNumber"
    }
  }, [_vm.part.homologation && _vm.part.homologation.serialNumber ? _c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('RestartHomologationSerialNumberFileGenerationTitle')) + " ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('RestartHomologationSerialNumberFileGenerationText1', {
    partName: _vm.part.name
  })) + " "), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.part.homologation.serialNumber.csgStatus) + ".")])]), _vm.part.status !== 'HOMOLOGATION' || _vm.part.homologation.status !== 'NEW' && _vm.part.homologation.status !== 'CSG_COMPUTATION' && _vm.part.homologation.status !== 'SUPPLIER_QUOTATION' ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('CannotRestartCSGNow')) + " ")]) : _vm.part.homologation.serialNumber.csgStatus === 'VALID' || _vm.part.homologation.serialNumber.csgStatus === 'IN_PROGRESS' ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('RestartProcessNotAdvised')) + " ")]) : _vm._e(), _c('v-divider'), _vm.part ? _c('SerialNumberCSGParametersSelector', {
    ref: "serialNumberCSGParametersSelectorRef",
    attrs: {
      "part": _vm.part
    }
  }) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeDialogHomologationSerialNumberFileGeneration
    }
  }, [_c('v-icon', {
    staticClass: "pr-2"
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loadingAPI,
      "color": "success",
      "disabled": _vm.part.status !== 'HOMOLOGATION' || _vm.part.homologation.status !== 'NEW' && _vm.part.homologation.status !== 'CSG_COMPUTATION' && _vm.part.homologation.status !== 'SUPPLIER_QUOTATION'
    },
    on: {
      "click": _vm.restartHomologationSerialNumberFileGeneration
    }
  }, [_c('v-icon', {
    staticClass: "pr-2"
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")], 1)], 1)], 1) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    model: {
      value: _vm.homologationRejectionMessagesDialog,
      callback: function ($$v) {
        _vm.homologationRejectionMessagesDialog = $$v;
      },
      expression: "homologationRejectionMessagesDialog"
    }
  }, [_c('HomologationRejectionMessages', {
    ref: "homologationRejectionMessages",
    attrs: {
      "admin": true,
      "part": _vm.part,
      "editable": true,
      "display-close-buttons": true
    },
    on: {
      "close": _vm.closeHomologationRejectionMessagesDialog,
      "modifyRejectionInformation": _vm.modifyHomologationRejectionInformation
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }