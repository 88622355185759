var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "partStatusOverview"
  }, [_c('div', [_c('span', {
    staticClass: "d-flex justify-space-between"
  }, [_c('h4', {
    staticClass: "headline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Status')) + " : " + _vm._s(_vm.$t(_vm.part.status)) + " ")]), _c('v-btn', {
    attrs: {
      "x-small": "",
      "fab": "",
      "color": "info"
    },
    on: {
      "click": _vm.activateModal
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-pen ")])], 1)], 1)]), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-timeline', {
    staticClass: "mt-4",
    attrs: {
      "dense": ""
    }
  }, [_c('v-timeline-item', {
    attrs: {
      "small": "",
      "color": _vm.step > 1 ? 'success' : _vm.step === 1 ? 'orange' : 'info',
      "icon": _vm.step > 1 ? 'fas fa-check' : ''
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('NEW')) + " ")])]), _c('v-timeline-item', {
    attrs: {
      "small": "",
      "color": _vm.step > 2 && _vm.part.homologation && _vm.part.homologation.serialNumber && _vm.brand.features.enableSerialNumber ? 'success' : _vm.step === 2 ? 'orange' : 'info',
      "icon": _vm.step > 2 && _vm.part.homologation && _vm.part.homologation.serialNumber && _vm.brand.features.enableSerialNumber ? 'fas fa-check' : ''
    }
  }, [_c('span', {
    class: _vm.part.homologation && !_vm.part.serialNumber || !_vm.brand.features.enableSerialNumber ? 'text-decoration-line-through' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t('CSG_COMPUTATION')) + " ")])]), _c('v-timeline-item', {
    attrs: {
      "small": "",
      "color": _vm.step > 3 ? !_vm.part.homologation || _vm.part.homologation.status !== 'REJECTED' ? 'success' : 'error' : _vm.step === 3 ? 'orange' : 'info',
      "icon": _vm.step > 3 ? !_vm.part.homologation || _vm.part.homologation.status !== 'REJECTED' ? 'fas fa-check' : 'fas fa-times' : ''
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('SUPPLIER_QUOTATION')) + " ")])]), _c('v-timeline-item', {
    attrs: {
      "small": "",
      "color": _vm.step > 4 ? 'success' : _vm.step === 4 ? 'orange' : 'info',
      "icon": _vm.step > 4 ? 'fas fa-check' : ''
    }
  }, [!_vm.part.homologation || _vm.part.homologation.status !== 'REJECTED' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('VALIDATION')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('REJECTED')) + " ")])]), _c('v-timeline-item', {
    attrs: {
      "small": "",
      "color": _vm.step > 5 ? 'success' : _vm.step === 5 ? 'orange' : 'info',
      "icon": _vm.step > 5 ? 'fas fa-check' : ''
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('READY')) + " ")])]), _c('v-timeline-item', {
    attrs: {
      "small": "",
      "color": _vm.step > 6 ? 'success' : _vm.step === 6 ? 'orange' : 'info',
      "icon": _vm.step > 6 ? 'fas fa-check' : ''
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ORDERED')) + " ")])]), _c('v-timeline-item', {
    attrs: {
      "small": "",
      "color": _vm.part.status === 'HOMOLOGATED' ? 'success' : _vm.step === 7 ? 'orange' : 'info',
      "icon": _vm.part.status === 'HOMOLOGATED' ? 'fas fa-check' : ''
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('TESTING')) + " ")])])], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    model: {
      value: _vm.modalEditStatus,
      callback: function ($$v) {
        _vm.modalEditStatus = $$v;
      },
      expression: "modalEditStatus"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('StatusModification')) + " ")]), _c('v-btn', {
    attrs: {
      "color": "error",
      "small": ""
    },
    on: {
      "click": _vm.deactivateModal
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")])], 1)], 1), _c('v-card-text', [_c('v-divider', {
    staticClass: "mb-3"
  }), _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('CurrentPartStatus1')) + " ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.part.status)) + " ( " + _vm._s(_vm.part.status) + " ) ")])]), _vm.part.status === 'HOMOLOGATION' ? _c('p', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('CurrentPartHomologationStatus1')) + " ")]), _vm.part.homologation ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.part.homologation.status)) + " ( " + _vm._s(_vm.part.homologation.status) + " ) ")]) : _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('NotInitialized')) + " ")])]) : _vm._e(), _c('p', [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "warning"
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('WarningHomologationModification')) + " ")], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('PartStatus')) + " ")]), _c('p', [_c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "text-none mr-2",
          attrs: {
            "color": "info",
            "disabled": !_vm.part.dna || !_vm.part.dna.completed || _vm.part.homologation && _vm.part.homologation.status !== _vm.homologationStatusList[_vm.selectedHomologationStatus],
            "ripple": false
          }
        }, on), [_vm._v(" " + _vm._s(_vm.$t(_vm.statusList[_vm.selectedStatus])) + " ")])];
      }
    }])
  }, [_c('v-list', [_c('v-list-item-group', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.selectedStatus,
      callback: function ($$v) {
        _vm.selectedStatus = $$v;
      },
      expression: "selectedStatus"
    }
  }, [_vm._l(_vm.statusList, function (status, i) {
    return [_c('v-list-item', {
      key: i,
      attrs: {
        "disabled": status === _vm.statusList[_vm.selectedStatus]
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(status)))])], 1)];
  })], 2)], 1)], 1), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": _vm.part.status === _vm.statusList[_vm.selectedStatus],
      "loading": _vm.loading
    },
    on: {
      "click": _vm.modifyPartStatus
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1)], 1)]), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('PartHomologationStatus')) + " ")]), _c('p', [_c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          staticClass: "text-none mr-2",
          attrs: {
            "color": "info",
            "disabled": !_vm.part.homologation || _vm.part.status !== 'HOMOLOGATION' || _vm.part.status !== _vm.statusList[_vm.selectedStatus],
            "ripple": false
          }
        }, on), [_vm.part.homologation ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.homologationStatusList[_vm.selectedHomologationStatus])) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('NotInitialized')) + " ")])])];
      }
    }])
  }, [_vm.part.homologation ? _c('v-list', [_c('v-list-item-group', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.selectedHomologationStatus,
      callback: function ($$v) {
        _vm.selectedHomologationStatus = $$v;
      },
      expression: "selectedHomologationStatus"
    }
  }, [_vm._l(_vm.homologationStatusList, function (homologationStatus, i) {
    return [_c('v-list-item', {
      key: i,
      attrs: {
        "disabled": !_vm.part.homologation || !_vm.possibleHomologations[_vm.part.homologation.status].has(homologationStatus) || _vm.homologationStatusList[_vm.selectedHomologationStatus] === homologationStatus
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(homologationStatus)))])], 1)];
  })], 2)], 1) : _vm._e()], 1), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": !_vm.part.homologation || _vm.part.homologation.status === _vm.homologationStatusList[_vm.selectedHomologationStatus],
      "loading": _vm.loading
    },
    on: {
      "click": _vm.modifyPartHomologationStatus
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1)], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }