<template lang="html" src="./pageHomologation.template.vue"></template>

<style lang="scss" src="./pageHomologation.scss"></style>

<script>
import {
  ApiErrorParser,
  DownloadPartFile,
  DownloadPartViewer3dFile,
  DnaFormV3,
  HomologationRejectionMessages,
  ObjectCompare,
  ViewerCriticalDimension,
  ViewerObjectsSplitter,
  PriceEstimatesV2,
  PythonStatus
} from '@cloudmanufacturingtechnologies/portal-components';

import PartStatusOverview from '../../components/partStatusOverview/PartStatusOverview.vue';
import SerialNumberCSGParametersSelector from '../../components/serialNumberCSGParametersSelector/SerialNumberCSGParametersSelector';

const i18nData = require('./pageHomologation.i18n');

export default {
  name: 'PageHomologation',
  components: {
    DnaFormV3,
    ObjectCompare,
    HomologationRejectionMessages,
    ViewerCriticalDimension,
    ViewerObjectsSplitter,
    PriceEstimatesV2,
    PythonStatus,
    PartStatusOverview,
    SerialNumberCSGParametersSelector
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      arrayBuffer: null,
      dnaSizes: {
        width: null,
        length: null,
        depth: null,
      },
      brand: null,
      closeDnaModal: false,
      dnaResult: null,
      dnaFormModal: false,
      dnaFormWarningModal: false,
      editorMode: false,
      headersDialogSuppliers: [],
      homologationDialogStep: 1,
      keyComponent: 0,
      loading: false,
      modifiedHomologation: false,
      dialogLeavePage: false,
      dialogBeforeFinishHomologationLabel: false,
      dialogBeforeModifyPartAndHomologationStatus: false,
      dialogSendToBCMSupplier: false,
      dialogSupplier: false,
      part: null,
      partStatus: null,
      link: null,
      linkToLeave: '',
      materials: [],
      materialPart: null,
      printerBrandModified: false,
      printerModelModified: false,
      materialModified: false,
      finishModified: false,
      manufacturingParametersModified: false,
      newStatus: null,
      needModel: false,
      partFileDataURL: null,
      printers: null,
      printersBrand: [],
      printersModel: [],
      pricesHomologation: [],
      priceEstimates: null,
      priceEstimates2: null,
      publicPrices: true,
      privatePrices: true,
      priceDetected: false,
      resetPrice: false,
      quantityPart: 1,
      realSizes: {
        width: null,
        length: null,
        depth: null,
      },
      requiredPrices: false,
      requiredWeight: false,
      requiredManufacturingInfo: false,
      searchDialogSuppliers: '',
      statusList: ['NEW', 'HOMOLOGATION', 'HOMOLOGATED'],
      supplierSelected: null,
      supplierDatatableKey: 0,
      suppliers: [],
      suppliersWithCompatiblePrinters: new Set(),
      suppliersEstimatedPrices: null,
      showButtonToSave: false,
      technologyPart: null,
      weight: null,
      modifiedSizeAndWeight: false,
      partSizeAndWeightEdit: false,
      boxes: [],
      editingPrice: false,
      pricesRangeRules: [],
      technologiesAndMaterials: null,
      viewer3dFile: null,
      partFile: null,
      partQuotes: [],
      // Serial number variables
      dialogGenerateHomologationSerialNumber: false,
      loadingAPI: false,
      tooltipsCSG: [],
      warningCloseDnaForm: false,
      homologationRejectionMessagesDialog: false,
      loadingBoxes: false,
      //Update quotes when price change
      updateQuoteDialog: false
    };
  },
  computed: {
    currentQuotes() {
      return this.partQuotes.filter(q => {
        return !['ACCEPTED', 'CLOSED', 'DECLINED'].includes(q.status)
          && (
            q.unconfiguredItems.some(uI => {
              return uI.part === this.part._id;
            })
            || q.dispatch.some(d => {
              return !['DELIVERED', 'CANCELED'].includes(d.status) && d.items.some(i => {
                return i.part === this.part._id;
              });
            })
          );
      });
    },
    currentQuotesValues() {
      const arr = [0];
      for(let i = 1; i < this.currentQuotes.length;  i++) {
        arr.push(i);
      }
      return arr;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.link = from.path;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.linkToLeave === '' && this.modifiedHomologation === true) {
      this.dialogLeavePage = true;
      this.linkToLeave = `/#${to.path}`;
    } else {
      this.dialogLeavePage = false;
      next();
    }
  },
  created() {
    this.tooltipsCSG = {
      IDLE: this.$t('StatusIdleTooltip'),
      IN_PROGRESS: this.$t('StatusInProgressTooltip'),
      VALID: this.$t('StatusValidTooltip'),
      ERROR: this.$t('StatusErrorTooltip'),
      INVALID: this.$t('StatusErrorTooltip'),
    };
    this.getAllTechnologiesAndMaterials();
    this.getBrand();
    this.getAllSuppliersEstimatedPrices();
    this.getBrandPartEstimatedPrices();
    this.getBrandPartEstimatedPricesV2();
    this.getBrandPartQuotes();
  },
  updated() {
    if (this.supplierSelectedForDatatable) {
      this.supplierSelected = this.supplierSelectedForDatatable[0];
    }
  },
  mounted() {
    this.headersDialogSuppliers = [
      { text: this.$t('supplier'), value: 'supplier' },
      { text: this.$t('city'), value: 'city' },
      { text: this.$t('country'), value: 'country' },
      { text: this.$t('unitaryPrice'), value: 'unitaryPrice' },
      { text: this.$t('bulkPrice'), value: 'bulkPrice' },
      { text: this.$t('action'), value: 'action', align: 'center' },
    ];
  },
  methods: {
    /**
     * ADD A NEW PRICE FORM
     */
    addPricesForm() {
      this.pricesHomologation.push({
        quantity: null,
        purchasePrice: null,
        margin: null,
        sellingPrice: null,
      });
      this.editingPrice = true;
    },
    /**
     * VERIFICATION OF QUANTITY IN THE FORM PRICES
     */
    calculatePrices(priceObject, typeOf, index) {
      if (priceObject[typeOf] < 0) {
        priceObject[typeOf] = null;
        this.$notification.notify(
          'DANGER',
          this.$t('You cannot enter negative number')
        );
      } else if (priceObject[typeOf] === 0 && typeOf === 'quantity') {
        priceObject[typeOf] = null;
        this.$notification.notify(
          'DANGER',
          this.$t('You cannot enter a quantity of less than 1')
        );
      } else {
        switch (typeOf) {
        case 'quantity':
          if (!priceObject.quantityRequired) {
            this.part.homologation.prices.forEach((price2, index2) => {
              if (index !== index2) {
                if (priceObject.quantity === price2.quantity) {
                  priceObject.quantity = null;
                  this.$notification.notify(
                    'DANGER',
                    this.$t('cantHaveTwoPriceForSameQantity')
                  );
                  return;
                }
              }
            });
          }
          break;
        case 'purchasePrice':
          if (Number.isFinite(priceObject.purchasePrice)) {
            if (priceObject.purchasePrice === 0) {
              priceObject.margin = 0;
              priceObject.sellingPrice = 0;
              break;
            }

            if (
              priceObject.sellingPrice &&
              priceObject.purchasePrice > priceObject.sellingPrice
            ) {
              priceObject.sellingPrice = priceObject.purchasePrice;
            }

            if (Number.isFinite(priceObject.sellingPrice)) {
              priceObject.margin = this.roundToDecimals(
                ((priceObject.sellingPrice - priceObject.purchasePrice) /
                  priceObject.purchasePrice) *
                  100
              );
            } else {
              priceObject.sellingPrice = Math.ceil(
                (1 + this.brand.features.defaultMargin / 100) *
                  priceObject.purchasePrice
              );
              priceObject.margin =
                100 * (priceObject.sellingPrice / priceObject.purchasePrice) -
                100;
            }
          }
          break;
        case 'sellingPrice':
          if (Number.isFinite(priceObject.sellingPrice)) {
            if (priceObject.sellingPrice === 0) {
              priceObject.margin = 0;
              priceObject.purchasePrice = 0;
              break;
            }

            if (priceObject.sellingPrice < priceObject.purchasePrice) {
              priceObject.sellingPrice = priceObject.purchasePrice;
            }

            if (Number.isFinite(priceObject.purchasePrice)) {
              priceObject.margin = this.roundToDecimals(
                ((priceObject.sellingPrice - priceObject.purchasePrice) /
                  priceObject.purchasePrice) *
                  100
              );
            }
          }
          break;
        default:
          this.$notification.notify(
            'DANGER',
            this.$t('Calculation is not possible')
          );
        }
        this.testPricesAndCompleteMissing(this.pricesHomologation);
        if (
          priceObject.quantity &&
          Number.isFinite(priceObject.purchasePrice) &&
          Number.isFinite(priceObject.sellingPrice) &&
          !Number.isNaN(Number.parseFloat(priceObject.margin))
        ) {
          this.modifiedHomologation = true;
        }
        this.forceRerender();
      }
    },
    /**
     * CHANGE THE STATUS OF THE PART
     */
    modifyPartStatus(status) {
      this.loading = true;
      const newStatus = new this.$BcmModel.ModifyPartStatusBody(status);
      this.$apiInstance
        .modifyPartStatus(this.$route.params.partUUID, newStatus)
        .then(
          () => {
            this.$notification.notify(
              'SUCCESS',
              this.$t('PartStatusModified')
            );
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
            this.newStatus = null;
          }
        )
        .finally(() => {
          this.dialogBeforeModifyPartAndHomologationStatus = false;
          this.loading = false;
          this.getAdminBrandPart();
        });
    },
    chooseSupplier(supplier) {
      this.supplierSelected = supplier;
      this.supplierDatatableKey += 1;
    },
    /**
     *  CREATE HOMOLOGATION BASICS PROPERTIES
     */
    createHomologationBasicsProperties(supplier) {
      setTimeout(
        function() {
          if ((this.supplierSelected && (!this.part.homologation || !this.part.homologation.supplier))
            || (this.part.homologation.supplier && this.supplierSelected._id !== this.part.homologation.supplier._id)) {
            this.supplierSelected = supplier;
            this.modifyPartHomologationSupplier();
          }
          this.dialogSupplier = false;
        }.bind(this),
        300
      );
    },
    /**
     *  CHANGE STATUS HOMOLOGATION
     */
    modifyHomologationStatus(status, sendEmail = false) {
      if(status === 'READY' && this.currentQuotes.length > 0) {
        this.updateQuoteDialog = true;
      }
      const newHomologationStatus = new this.$BcmModel.ModifyPartHomologationStatusBody(status);
      newHomologationStatus.sendEmail = sendEmail;
      this.$apiInstance
        .modifyPartHomologationStatus(
          this.$route.params.partUUID,
          newHomologationStatus
        )
        .then(
          () => {
            this.dialogBeforeFinishHomologationLabel = false;
            this.part.homologation.status = status;
            this.modifiedHomologation = false;
            this.$notification.notify(
              'SUCCESS',
              this.$t('HomologationStatusModified')
            );
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        ).finally(() => {
          this.getAdminBrandPart();
        });
    },
    deletePrice(index) {
      this.pricesHomologation.splice(index, 1);
      this.editingPrice = false;
    },
    cancelPriceForm() {
      this.getAdminBrandPart();
      this.pricesRangeRules = [];
    },
    getAllTechnologiesAndMaterials() {
      this.$apiInstance
        .getAllTechnologiesAndMaterialsCompatibilities()
        .then((technologiesAndMaterials) => {
                this.technologiesAndMaterials = technologiesAndMaterials;
              },
              /**
               * ERROR GET ALL TECHNOLOGIES AND MATERIALS
               */
              (error) => {
                this.$notification.notify(
                  'DANGER',
                  this.$t(ApiErrorParser.parse(error))
                );
              });
    },
    getBrandPartQuotes() {
      this.partQuotes = [];
      this.$apiInstance.getBrandPartQuotes(
        this.$route.params.brandUUID,
        this.$route.params.partUUID
      ).then(data => {
        this.partQuotes = data;
      });
    },
    /**
     * GET BRAND PART
     */
    getAdminBrandPart() {
      this.$apiInstance
        .getAdminBrandPart(this.$route.params.partUUID)
        .then(
          (partData) => {
            this.part = partData;
            if(!this.suppliers?.length) {
              this.getAllSuppliers();
            }
            this.getBrandPartViewer3DFile();
            this.partStatus = this.part.status;
            if (this.part.size) {
              this.realSizes = this.part.size;
              if (this.part.weight) {
                this.requiredWeight = true;
              }
            }
            this.weight = this.part.weight;
            this.editingPrice = false;
            this.forceRerender();

            const partH = this.part.homologation;

            /**
             * Homologation setup
             */
            if(partH) {
              partH.supplier
                ? (this.supplierSelected = this.part.homologation.supplier)
                : (this.supplierSelected = null);
              this.technologyPart = partH.technology;
              this.materialPart = partH.material;
              this.pricesHomologation = [];

              if (partH.prices) {
                for (let i = 0; i < partH.prices.length; i++) {
                  if (!partH.prices[i].margin && !partH.prices[i].sellingPrice) {
                    if(partH.prices[i].purchasePrice > 0) {
                      partH.prices[i].sellingPrice = Math.ceil((1 + this.brand.features.defaultMargin / 100) * partH.prices[i].purchasePrice);
                      partH.prices[i].margin = 100 * (partH.prices[i].sellingPrice / partH.prices[i].purchasePrice) - 100;
                    } else {
                      partH.prices[i].sellingPrice  = 0;
                      partH.prices[i].margin = 0;
                    }
                  }
                  this.pricesHomologation.push(Object.assign({}, partH.prices[i]));
                }
                this.pricesHomologation.forEach((price) => {
                  if (price.quantity === 1) {
                    price.quantityRequired = true;
                  }
                });
              }

              if (partH.prices && partH.prices.length > 0) {
                this.priceDetected = true;
              }
              this.updatePriceRangeRules();

              this.modifiedHomologation = false;
              this.partSizeAndWeightEdit = false;
              this.testPricesAndCompleteMissing(this.pricesHomologation);

              if (partH.serialNumber && partH.csgStatus !== 'VALID' && partH.csgStatus !== 'ERROR') {
                this.updateCSGStatus();
              }

              if(!partH.manufacturingInformations) {
                partH.manufacturingInformations = {};
              }

              const partMI = partH.manufacturingInformations;

              /**
               * Homologation manufacturing information setup
               */
              if (!partMI.printer) {
                partMI.printer = {};
              }
              
              if (partMI.printer.brand) {
                partMI.printer.brand = partMI.printer.brand.split('_').join(' ');
              }

              if (partMI.printer.model) {
                partMI.printer.model = partMI.printer.model.split('_').join(' ');
              }

              this.requiredManufacturingInfo = true;
              /** Get material in homologation and prefill the field */

              if(this.part.dna) {
                const configurationObject =  this.part.dna.configuration;
                /** Get material in homologation and prefill the field */
                if (partH.material && (!partMI.material)) {
                  partMI.material = this.$t(partH.material);
                  if(configurationObject.color && configurationObject.color.original) {
                    partMI.material = partMI.material + ' - ' + this.$t(configurationObject.color.original);
                  }
                }
              }

              this.getAllPrinters();
            }
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        );
    },
    /**
     * GET BRAND
     */
    getBrand() {
      this.$apiInstance.getAdminBrand(this.$route.params.brandUUID).then(
        (brandData) => {
          this.brand = brandData;
          this.getAdminBrandPart();
        },
        (error) => {
          /**
           * ERROR GET BRAND
           */
          ApiErrorParser.parse(error);
        }
      );
      
    },
    /**
     * Update the set of suppliers with compatible printers for the part
     */
    updateSuppliersWithCompatiblePrinters() {
      if(!this.part?.size || !this.part?.dna?.technology) {
        return;
      }
      this.suppliersWithCompatiblePrinters = new Set();
      for(const supplier of this.suppliers) {
        this.$apiInstance.getSupplierPrinters(supplier._id).then(
          (printers) => {
            for(const printer of printers) {
              if(printer.technology === this.part.dna.technology) {
                if(printer.technology !== 'MBJ') {
                  if(this.canFit(this.part.size, printer.build_volume)) {
                    this.suppliersWithCompatiblePrinters.add(supplier._id);
                  }
                } else {
                  /**
                   * We should simulate that the printer size is half the reality
                   */
                  const reducedPrinterBuildVolume = {
                    width: printer.build_volume.width / 2,
                    depth: printer.build_volume.depth / 2,
                    height: printer.build_volume.height / 2
                  };
                  if(this.canFit(this.part.size, reducedPrinterBuildVolume)) {
                    this.suppliersWithCompatiblePrinters.add(supplier._id);
                  }
                }
              }
            }
          },
          (error) => {
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }
        );
      }
    },
    canFit(partSize, printerSize) {
      return (partSize.width <= printerSize.width && partSize.depth <= printerSize.depth && partSize.height <= printerSize.height)
        || (partSize.width <= printerSize.width && partSize.height <= printerSize.depth && partSize.depth <= printerSize.height)
        || (partSize.depth <= printerSize.width && partSize.width <= printerSize.depth && partSize.height <= printerSize.height)
        || (partSize.depth <= printerSize.width && partSize.height <= printerSize.depth && partSize.width <= printerSize.height)
        || (partSize.height <= printerSize.width && partSize.width <= printerSize.depth && partSize.depth <= printerSize.height)
        || (partSize.height <= printerSize.width && partSize.depth <= printerSize.depth && partSize.width <= printerSize.height);
    },
    /**
     * GET ALL PRINTERS
     */
    getAllPrinters() {
      if (this.supplierSelected) {
        this.$apiInstance.getSupplierPrinters(this.supplierSelected._id).then(
          (printersData) => {
            this.printers = printersData;
            this.getBrands();
            if (this.part.homologation && this.part.homologation.manufacturingInformations.printer) {
              this.getModels(this.part.homologation.manufacturingInformations.printer.brand);
            }
          },
          (error) => {
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }
        );
      }
    },
    getBrands() {
      this.printersBrand = [];
      for (let i = 0; i < this.printers.length; i++) {
        if (
          this.printers[i].technology === this.technologyPart &&
          !this.printersBrand.includes(
            this.printers[i].brand.split('_').join(' ')
          )
        ) {
          this.printersBrand.push(this.printers[i].brand.split('_').join(' '));
        }
      }
    },
    getModels(brand) {
      this.printersModel = [];
      for (let i = 0; i < this.printers.length; i++) {
        if (
          this.printers[i].brand.split('_').join(' ') === brand &&
          this.printers[i].technology === this.technologyPart
        ) {
          this.printersModel.push(this.printers[i].model.split('_').join(' '));
        }
      }
    },
    isObject(value) {
      return value && typeof value === 'object' && value.constructor === Object;
    },
    testPricesAndCompleteMissing(prices) {
      let testFor1 = false;
      let testForRequiredPrices1 = false;
      for (let i = 0; i < prices.length; i++) {
        if (prices[i].quantity === 1) {
          testFor1 = true;
          if (
            Number.isFinite(prices[i].purchasePrice) &&
            Number.isFinite(prices[i].sellingPrice)
          ) {
            testForRequiredPrices1 = true;
          }
          break;
        }
      }
      if (testFor1 === false) {
        prices.push({
          quantity: 1,
          purchasePrice: null,
          margin: null,
          sellingPrice: null,
          quantityRequired: true,
        });
      }
      this.requiredPrices = testForRequiredPrices1 === true;
    },
    getAllSuppliers() {
      this.$apiInstance
        .getAllSuppliers(
          this.$route.params.brandUUID,
          this.$route.params.partUUID
        )
        .then(
          (data) => {
            this.suppliers = [];
            if(this.part.dna && this.part.dna.technology) {
              const dnaTechnology = this.part.dna.technology;
              const dnaMaterial = this.part.dna.material;
              for (const supplier of data) {
                if (
                  supplier.technologies &&
                  supplier.technologies[dnaTechnology] &&
                  supplier.technologies[dnaTechnology].includes(dnaMaterial) &&
                  (
                    (supplier.public && this.brand.features.accessToBCMNetwork)
                    || (this.brand.features.privateSuppliers.some(psInfo=>{return psInfo.supplier === supplier._id;}))
                  )
                ) {
                  this.suppliers.push(supplier);
                }
              }
            }
            this.updateSuppliersWithCompatiblePrinters();
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        );
    },
    /**
     * Get All supplier estimated prices
     */
    getAllSuppliersEstimatedPrices() {
      this.$apiInstance
        .getBrandPartSuppliersEstimatedPrices(
          this.$route.params.brandUUID,
          this.$route.params.partUUID
        )
        .then(
          (data) => {
            const suppliersEstimatedPrices = {};
            data.forEach((supplierEstimatedPrices) => {
              suppliersEstimatedPrices[supplierEstimatedPrices.supplier] =
                supplierEstimatedPrices.estimatedPrices;
            });
            this.suppliersEstimatedPrices = suppliersEstimatedPrices;
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        );
    },
    /**
     * Get Public estimated prices
     */
    getBrandPartEstimatedPrices() {
      this.$apiInstance
        .getBrandPartEstimatedPrices(
          this.$route.params.brandUUID,
          this.$route.params.partUUID
        )
        .then(
          (priceEstimates) => {
            this.priceEstimates = priceEstimates;
          },
          (error) => {
            this.$notification.notify('ERROR',ApiErrorParser.parse(error));
          }
        );
    },
    getBrandPartEstimatedPricesV2() {
      let source = 'both';

      if(!this.publicPrices) {
        source = 'private';
      }

      if(!this.privatePrices) {
        source = 'public';
      }
      this.$apiInstance
        .getBrandPartEstimatedPricesV2(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          {source: source}
        )
        .then(
          (priceEstimates) => {
            this.priceEstimates2 = priceEstimates;
          },
          (error) => {
            this.$notification.notify('ERROR',ApiErrorParser.parse(error));
          }
        );
    },
    openViewerObjectsSplitter() {
      if(!this.partFile) {
        DownloadPartFile.downloadPartFile(
          this.$apiInstance,
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          'stl'
        )[0].then((response) => {
          this.partFile = {
            extension: response.extension,
            buffer: Buffer.from(response.buffer),
          };
        });
      }
      this.$refs.viewerObjectsSplitterRef.open();
    },
    /**
     * Hook leave page
     */
    leavePage() {
      location.replace(this.linkToLeave);
    },
    /**
     * MODIFY MANUFACTURING INFORMATIONS
     */
    modifyManufacturingInformations() {
      if (this.part.homologation && this.part.homologation.manufacturingInformations.printer) {
        this.part.homologation.manufacturingInformations.printer.brand =
          this.part.homologation.manufacturingInformations.printer.brand.replace(/\s+/g, '_');
        this.part.homologation.manufacturingInformations.printer.model =
          this.part.homologation.manufacturingInformations.printer.model.replace(/\s+/g, '_');
      }
      const manufacturingInformations =
        new this.$BcmModel.ManufacturingInformations(
          this.part.homologation.manufacturingInformations.printer,
          this.part.homologation.manufacturingInformations.material
        );
      manufacturingInformations.manufacturing_parameters =
        this.part.homologation.manufacturingInformations.manufacturing_parameters;
      manufacturingInformations.finish = this.part.homologation.manufacturingInformations.finish;
      this.$apiInstance
        .modifyHomologationManufacturingInformations(
          this.$route.params.partUUID,
          manufacturingInformations
        )
        .then(
          () => {
            if (
              this.part.homologation.manufacturingInformations.printer &&
              this.part.homologation.manufacturingInformations.material
            ) {
              this.requiredManufacturingInfo = true;
            }
            this.$notification.notify(
              'SUCCESS',
              this.$t('manufacturingInformationsModifiedSuccessfully')
            );
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        )
        .finally(() => {
          this.showButtonToSave = false;
          this.printerBrandModified = false;
          this.printerModelModified = false;
          this.materialModified = false;
          this.manufacturingParametersModified = false;
          this.finishModified = false;
          this.getAdminBrandPart();
        });
    },
    /**
     * CANCEL MANUFACTURING HOMOLOGATION
     */
    cancelManufacturingInformations() {
      this.showButtonToSave = false;
      this.printerBrandModified = false;
      this.printerModelModified = false;
      this.materialModified = false;
      this.manufacturingParametersModified = false;
      this.finishModified = false;
      this.getAdminBrandPart();
    },
    /**
     *  MODIFY HOMOLOGATION PRICES
     */
    modifyHomologationPrices() {
      if (this.$refs.pricesForm.validate()) {
        if (!this.part.homologation.status) {
          this.part.homologation.status = 'NEW';
        }
        const formattedPricesToSend = [];
        for (let i = 0; i < this.pricesHomologation.length; i++) {
          if (
            this.pricesHomologation[i].quantity !== 0 &&
            Number.isFinite(this.pricesHomologation[i].purchasePrice) &&
            Number.isFinite(this.pricesHomologation[i].margin) &&
            Number.isFinite(this.pricesHomologation[i].sellingPrice)
          ) {
            if (this.pricesHomologation[i].quantityRequired)
              delete this.pricesHomologation[i].quantityRequired;
            formattedPricesToSend.push(this.pricesHomologation[i]);
          }
        }
        const newHomologationPrices =
          new this.$BcmModel.ModifyPartHomologationPricesBody(
            formattedPricesToSend
          );
        this.$apiInstance
          .modifyPartHomologationPrices(
            this.$route.params.partUUID,
            newHomologationPrices
          )
          .then(
            (adminPart) => {
              this.modifiedHomologation = false;
              this.$notification.notify(
                'SUCCESS',
                this.$t('pricesModifiedSuccess')
              );
              if(this.currentQuotes.length > 0) {
                this.part = adminPart;
                this.updateQuoteDialog = true;
              }
              this.getAdminBrandPart();
            },
            /**
             * ERROR GET BRAND PART
             */
            (error) => {
              this.$notification.notify(
                'DANGER',
                this.$t(ApiErrorParser.parse(error))
              );
            }
          );
      }
    },
    /**
     *  MODIFY HOMOLOGATION TECHNOLOGY AND MATERIAL
     */
    modifyPartHomologationSupplier() {
      const supplierBody =
        new this.$BcmModel.ModifyPartHomologationSupplierBody(
          this.supplierSelected._id
        );
      this.$apiInstance
        .modifyPartHomologationSupplier(
          this.$route.params.partUUID,
          supplierBody
        )
        .then(
          () => {
            this.modifiedHomologation = false;
            this.$notification.notify(
              'SUCCESS',
              this.$t('supplierTechnologyAndMaterialModifiedSuccess')
            );
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        )
        .finally(() => {
          this.getAdminBrandPart();
          this.requiredManufacturingInfo = false;
        });
    },
    /**
     * MODIFY PART SIZE AND WEIGHT
     */
    modifyPartSizeAndWeight() {
      const partSizeAndWeightBody =
        new this.$BcmModel.ModifyPartSizeAndWeightBody(
          this.weight,
          this.part.size
        );
      this.$apiInstance
        .modifyPartSizeAndWeight(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          partSizeAndWeightBody
        )
        .then(
          () => {
            this.$notification.notify(
              'SUCCESS',
              this.$t('SizeAndWeightModifiedSuccess')
            );
            this.getAdminBrandPart();
            this.estimateDeliveryBoxes();
            this.modifiedSizeAndWeight = false;
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        );
    },
    /**
     * BRAND PART SIZE AND WEIGHT
     */
    cancelPartSizeAndWeightEdit() {
      this.partSizeAndWeightEdit = false;
      this.getAdminBrandPart();
    },
    roundToDecimals(number) {
      return Math.round(number * 100) / 100;
    },
    sendToBCMSupplier() {
      this.loading = true;
      const sendHomologationToSupplierBody =
        new this.$BcmModel.SendHomologationToSupplierBody(
          this.supplierSelected._id,
          this.resetPrice
        );

      this.$apiInstance
        .sendHomologationToSupplier(
          this.part._id,
          sendHomologationToSupplierBody
        )
        .then(
          () => {
            this.$notification.notify(
              'SUCCESS',
              this.$t(
                'YourRequestForQuotationHasBeenSuccessfullyTransferredToTheSupplier',
                { supplier: this.supplierSelected.name }
              )
            );

            this.getAdminBrandPart();
          },
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        )
        .finally(() => {
          this.dialogSendToBCMSupplier = false;
          this.loading = false;
          this.resetPrice = false;
        });
    },

    verifyRealValue(typeOfValue) {
      if (typeOfValue) {
        if (this.realSizes[typeOfValue] > this.dnaSizes[typeOfValue]) {
          this.realSizes[typeOfValue] = null;
          this.$notification.notify(
            'DANGER',
            this.$t('The real value cannot be higher than the DNA value')
          );
        }
        if (this.realSizes[typeOfValue] === 0) {
          this.realSizes[typeOfValue] = null;
          this.$notification.notify(
            'DANGER',
            this.$t('The real value cannot be null or negative')
          );
        }
      }
      if (
        this.part.size.width &&
        this.part.size.depth &&
        this.part.size.height &&
        this.weight
      ) {
        this.modifiedSizeAndWeight = true;
        this.requiredWeight = true;
      }
    },
    arraysAreEqual(a1, a2) {
      a2.forEach((price) => {
        delete price.quantityRequired;
      });
      return (
        a1.length === a2.length &&
        a1.every((o, idx) => ObjectCompare.compare(o, a2[idx]))
      );
    },
    forceRerender() {
      this.keyComponent += 1;
    },
    estimateDeliveryBoxes() {
      this.boxes = [];
      this.loadingBoxes = true;

      const maxQt = Math.max(...this.part.homologation.prices.map(p => p.quantity), 1);

      this.$apiInstance
        .estimateShippingPartBoxes(this.$route.params.partUUID, maxQt)
        .then(
          (data) => {
            let quantities = [1,2,3,4,5,10,20,50,100];
            if(this.part.homologation?.prices.length) {
              quantities = this.part.homologation.prices.map(priceObj => {return priceObj.quantity;});
            }
            for(const quantity of quantities) {
              this.boxes.push({
                for: quantity,
                A3: {
                  capacity: data.A3.capacity,
                  weight: Math.min(quantity, data.A3.capacity) * this.part.weight / 1000,
                  quantity: Math.ceil(quantity / data.A3.capacity)
                },
                A5: {
                  capacity: data.A5.capacity,
                  weight: Math.min(quantity, data.A5.capacity) * this.part.weight / 1000,
                  quantity: Math.ceil(quantity / data.A5.capacity)
                },
                A6: {
                  capacity: data.A6.capacity,
                  weight: Math.min(quantity, data.A6.capacity) * this.part.weight / 1000,
                  quantity: Math.ceil(quantity / data.A6.capacity)
                },
                A9: {
                  capacity: data.A9.capacity,
                  weight: Math.min(quantity, data.A9.capacity) * this.part.weight / 1000,
                  quantity: Math.ceil(quantity / data.A9.capacity)
                },
                A16: {
                  capacity: data.A16.capacity,
                  weight: Math.min(quantity, data.A16.capacity) * this.part.weight / 1000,
                  quantity: Math.ceil(quantity / data.A16.capacity)
                },
              });
            }
          },
        ).finally(() => {
          this.loadingBoxes = false;
        });
    },
    /**
     * VERIFY PRICE RANGE
     */
    verifyPrice() {
      this.updatePriceRangeRules();
      setTimeout(this.$refs.pricesForm.validate, 200);
    },

    updatePriceRangeRules() {
      const pricesRange = [];
      this.pricesHomologation.forEach((current, index) => {
        const price = {
          quantityMin: null,
          quantityMax: null,
          min: current.purchasePrice,
          max: current.purchasePrice,
        };

        //On cherche la quantité la plus proche inférieur et supérieur
        this.pricesHomologation.forEach((test, index2) => {
          if (
            index2 !== index &&
            current.quantity !== test.quantity &&
            current.quantity > 0 &&
            current.purchasePrice > 0 &&
            test.purchasePrice &&
            test.purchasePrice > 0
          ) {
            if (test.quantity < current.quantity) {
              //Plus petite quantité donc le prix doit etre suppérieur
              if (!price.quantityMin || test.quantity >= price.quantityMin) {
                price.quantityMin = test.quantity; //la quantite la plus basse
                price.max = test.purchasePrice; //le prix le plus haut
              }
            } else {
              //Plus grande quantité donc le prix doit etre inférieur
              if (!price.quantityMax || test.quantity <= price.quantityMax) {
                price.quantityMax = test.quantity; //la quantite la plus haute
                price.min = test.purchasePrice; //le prix le plus bas
              }
            }
          }
        });

        const rules = [];
        //push empty
        rules.push((value) => !!value || this.$t('priceRequired'));
        if (!price.quantityMin) {
          price.max = null;
          //quantity min
          rules.push(
            (value) =>
              value >= price.min ||
              this.$t('PriceRangeSupTo', {
                min: price.min,
              })
          );
        } else if (!price.quantityMax) {
          price.min = null;
          //quantity max
          rules.push(
            (value) =>
              value <= price.max ||
              this.$t('PriceRangeInfTo', {
                max: price.max,
              })
          );
          rules.push(
            (value) =>
              value > 0 ||
              this.$t('PriceRangeSupTo', {
                max: 0,
              })
          );
        } else {
          //Between
          rules.push(
            (value) =>
              (value >= price.min && value <= price.max) ||
              this.$t('PriceRangeBetween', {
                min: price.min,
                max: price.max,
              })
          );
        }
        //push rules
        pricesRange.push(rules);
      });
      this.pricesRangeRules = pricesRange;
    },

    cancelDnaForm() {
      this.getAdminBrandPart();
    },
    saveDNA(dna) {
      this.$apiInstance
        .modifyAdminPartDNA(
          this.$route.params.partUUID,
          dna
        )
        .then(
          (data) => {
            const dna = data.dna;
            const technology = dna.technology.toLowerCase();
            const material = dna.material;
            const configurationObject = dna.configuration;
            const translatedFinishes = [];
            if (this.part.homologation && (technology !== this.part.homologation.technology.toLowerCase() || material !== this.part.homologation.material)) {

              this.part.homologation.manufacturingInformations.finish = '';
              this.part.homologation.manufacturingInformations.printer = null;
              this.part.homologation.manufacturingInformations.manufacturing_parameters = '';
              this.part.homologation.manufacturingInformations.material = this.$t(material) + ' - ' + this.$t(configurationObject.color.original); 

              if(dna.technology === 'FFF') {

                const translatedFFFConfiguration = [];

                translatedFFFConfiguration.push(this.$t('LayerSize') + ' : ' + dna.configuration.fffConfiguration.layerSize + 'mm');
                translatedFFFConfiguration.push(this.$t('Infill') + ' : ' + dna.configuration.fffConfiguration.infill + '%');

                this.part.homologation.manufacturingInformations.manufacturing_parameters += translatedFFFConfiguration.join('\n');

              }

              if(configurationObject.color) {
                if(configurationObject.color.dyeing) {
                  translatedFinishes.push(this.$t('Dyeing') + ' : ' + this.$t(configurationObject.color.dyeing));
                }
              }

              if (configurationObject.finishes && configurationObject.finishes.default) {
                configurationObject.finishes.default.forEach((finish) => {
                  translatedFinishes.push(this.$t(finish));
                });
              }

              if (configurationObject.finishes && configurationObject.finishes.optional) {
                configurationObject.finishes.optional.forEach((finish) => {
                  translatedFinishes.push(this.$t(finish));
                });
              }

              this.part.homologation.manufacturingInformations.finish += translatedFinishes.join('\n');

              this.modifyManufacturingInformations();
            } else {
              this.getAdminBrandPart();
            }
            this.editorMode = false;

          },
          (error) => {
            // this.$refs.dnaFormModal.savedResult(true);
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        );
    },
    /**
     * CriticalDimensions
     * Part critical dimensions updated
     */
    getBrandPartViewer3DFile() {
      const oReq = DownloadPartViewer3dFile.downloadPartViewer3dFile(
        this.$apiInstance,
        this.$route.params.brandUUID,
        this.$route.params.partUUID,
        new Date(this.part.viewer3dFile.created).getTime()
      );
      oReq.onloadend = () => {
        this.viewer3dFile = {
          extension: 'blsv',
          buffer: oReq.response,
        };
      };
    },
    saveBrandPartCriticalDimensions(criticalDimensions) {
      const modifyBrandPartCriticalDimensionsBody =
        new this.$BcmModel.ModifyBrandPartCriticalDimensionsBody(
          criticalDimensions
        );
      this.$apiInstance
        .modifyBrandPartCriticalDimensions(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          modifyBrandPartCriticalDimensionsBody
        )
        .then(
          (part) => {
            this.part = part;
            this.$notification.notify(
              'SUCCESS',
              this.$t('SuccessCriticalDimensions')
            );
            this.$refs.viewerCriticalDimension.refresh();
          },
          (error) => {
            this.$notification.notify(
              'DANGER',
              this.$t(ApiErrorParser.parse(error))
            );
          }
        );
    },
    openCriticalDimensionsTool() {
      this.$refs.viewerCriticalDimension.open();
    },
    updateCSGStatus(timeout = 2000) {
      if(this.$route.params.partUUID === this.part._id)
        this.$apiInstance
          .getAdminBrandPart(this.$route.params.partUUID)
          .then((partData) => {
            this.part.homologation.serialNumber = partData.homologation.serialNumber;
            if (
              this.part.homologation.serialNumber.csgStatus !== 'VALID' &&
              this.part.homologation.serialNumber.csgStatus !== 'ERROR'
            ) {
              setTimeout(this.updateCSGStatus, Math.min(2 * timeout, 30000));
            }
          });
    },
    restartHomologationSerialNumberFileGeneration() {
      this.loadingAPI = true;
      const opts = {};
      if(this.$refs.serialNumberCSGParametersSelectorRef?.checkbox) {
        opts['serialNumberCSGParametersBody'] = this.$BcmModel.SerialNumberCSGParametersBody.constructFromObject({
          size: this.$refs.serialNumberCSGParametersSelectorRef.customSize,
          height: this.$refs.serialNumberCSGParametersSelectorRef.customHeight
        });
      }

      this.$apiInstance
        .startPartHomologationSerialNumberCSG(this.$route.params.partUUID, opts)
        .then((data) => {
          this.closeDialogHomologationSerialNumberFileGeneration();
          this.updateCSGStatus();
          this.$notification.notify(
            'SUCCESS',
            this.$t('RestartedSerialNumberFileGeneration')
          );
        })
        .catch((error) => {
          this.$notification.notify(
            'DANGER',
            this.$t(ApiErrorParser.parse(error))
          );
        })
        .finally(() => {
          this.loadingAPI = false;
        });
    },
    openDialogHomologationSerialNumberFileGeneration() {
      this.dialogGenerateHomologationSerialNumber = true;
    },
    closeDialogHomologationSerialNumberFileGeneration() {
      this.dialogGenerateHomologationSerialNumber = false;
    },
    openHomologationRejectionMessagesDialog() {
      this.homologationRejectionMessagesDialog = true;
    },
    closeHomologationRejectionMessagesDialog() {
      this.homologationRejectionMessagesDialog = false;
    },
    modifyHomologationRejectionInformation(title, message, date, index) {
      const modifyAdminPartHomologationRejectionInformationBody = new this.$BcmModel.ModifyAdminPartHomologationRejectionInformationBody(
        title,
        message,
        date
      );

      this.$apiInstance.modifyAdminPartHomologationRejectionInformation(
        this.$route.params.partUUID,
        index,
        modifyAdminPartHomologationRejectionInformationBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('RejectionMessageModifiedSuccessfully'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.$refs.homologationRejectionMessages.stopLoading();
        this.getAdminBrandPart();
      });
    },
    async updateQuotesWithNewPrices() {
      //ForEach Quote
      for await (const q of this.currentQuotes) {
        //Get quote
        const quote = await this.getQuote(q._id);
        // For each dispatch and items 
        quote.dispatch.forEach(dispatch=>{
          dispatch.items.forEach(item =>{
            // find the item
            if(item.part._id === this.part._id) {
              const price = this.part.homologation.prices.findLast(price => price.quantity <= item.quantity);
              //Update item
              const modifyQuoteDispatchItemPricesBody = new this.$BcmModel.ModifyQuoteDispatchItemPricesBody(
                {
                  price: Number(item.purchase.price),
                  vatPercentage: Number(item.purchase.vatPercentage)
                },
                {
                  price: Number(price.sellingPrice),
                  vatPercentage: Number(item.selling.vatPercentage)
                }
              );
              this.modifyQuoteDispatchItemPrices(quote, dispatch.supplier, this.part._id, modifyQuoteDispatchItemPricesBody);
            }
          });
        });
      }
      this.updateQuoteDialog = false;
    },
    async getQuote(quoteUUID) {
      const quote = await this.$apiInstance.getAdminQuote(quoteUUID).then(
        (quoteData) => {
          return quoteData;
        },
        (error) => {
          /**
           * ERROR GET QUOTE
           *
           * Component BeelseNotifications used
           */
          this.$notification.notify('DANGER', ApiErrorParser.parse(error));
        }
      );
      return quote;
    },
    modifyQuoteDispatchItemPrices(quote, supplierUUID, partUUID, modifyQuoteDispatchItemPricesBody) {
      this.$apiInstance.modifyQuoteDispatchItemPrices(
        quote._id,
        supplierUUID,
        partUUID,
        modifyQuoteDispatchItemPricesBody
      ).then(() => {
        this.$notification.notify('SUCCESS', this.$t('partPriceUpdatedQuote', {name: quote.quoteNumber}));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      });
    },
  },
};
</script>
