<template lang="html" src="./partStatusOverview.template.vue"></template>

<style lang="scss" src="./partStatusOverview.scss"></style>

<script>

const i18nData = require('./partStatusOverview.i18n');
export default {
  name: 'PartStatusOverview',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    /**
     * The part object
     */
    part: {
      type: Object,
      required: true
    },
    /**
     * The brand owner of the part
     */
    brand: {
      type: Object,
      required: true
    }
  },
  watch: {
    part: {
      handler: function() {
        this.updateStatus();
        this.updateStep();
      },
      deep: true
    }
  },
  data() {
    return {
      formPartHomologationStatus: '',
      formPartStatus: '',
      homologationStatusList: [],
      loading: false,
      modalEditStatus: false,
      possibleHomologations: {},
      selectedStatus: '',
      selectedHomologationStatus: '',
      statusList: [],
      step: 0,
    };
  },
  created() {
    this.possibleHomologations = {
      'NEW': new Set(['NEW', 'SUPPLIER_QUOTATION', 'VALIDATION', 'READY']),
      'CSG_COMPUTATION': new Set(['NEW', 'CSG_COMPUTATION']),
      'SUPPLIER_QUOTATION': new Set(['NEW', 'SUPPLIER_QUOTATION', 'VALIDATION']),
      'REJECTED': new Set(['NEW', 'SUPPLIER_QUOTATION', 'REJECTED']),
      'VALIDATION': new Set(['NEW', 'SUPPLIER_QUOTATION', 'VALIDATION', 'READY', 'TESTING']),
      'READY': new Set(['NEW', 'SUPPLIER_QUOTATION', 'VALIDATION', 'READY', 'TESTING']),
      'ORDERED': new Set(['ORDERED']),
      'TESTING': new Set(['NEW', 'SUPPLIER_QUOTATION', 'VALIDATION', 'READY', 'TESTING'])
    };
    this.homologationStatusList = ['NEW', 'CSG_COMPUTATION', 'SUPPLIER_QUOTATION', 'REJECTED', 'VALIDATION', 'READY', 'ORDERED', 'TESTING'];
    this.statusList = ['NEW', 'HOMOLOGATION', 'HOMOLOGATED'];
    this.updateStatus();
    this.updateStep();
  },
  methods: {
    activateModal() {
      this.updateStatus();
      this.updateStep();
      this.modalEditStatus = true;
    },
    deactivateModal() {
      this.modalEditStatus = false;
      this.loading = false;
    },
    modifyPartStatus() {
      /**
       * Emit the new status to set
       */
      this.loading = true;
      this.$emit('modifyPartStatus', this.statusList[this.selectedStatus]);
    },
    modifyPartHomologationStatus() {
      /**
       * Emit the new homologation status to set
       */
      this.loading = true;
      this.$emit('modifyPartHomologationStatus', this.homologationStatusList[this.selectedHomologationStatus]);
    },
    updateStatus() {
      this.loading = false;
      this.selectedStatus = this.statusList.indexOf(this.part.status);
      this.selectedHomologationStatus = this.part.homologation ? this.homologationStatusList.indexOf(this.part.homologation.status) : 0;
    },
    updateStep() {
      if(!this.part.homologation) {
        this.step = 0;
        return;
      }
      switch(this.part.homologation.status) {
      case 'NEW':
        this.step = 1;
        break;
      case 'CSG_COMPUTATION':
        this.step = 2;
        break;
      case 'SUPPLIER_QUOTATION':
        this.step = 3;
        break;
      case 'VALIDATION':
      case 'REJECTED':
        this.step = 4;
        break;
      case 'READY':
        this.step = 5;
        break;
      case 'ORDERED':
        this.step = 6;
        break;
      case 'TESTING':
        this.step = 7;
        break;
      }
    }
  },
};
</script>
